<style>
    table.exam-time-table {
    border: 3px solid #000000;
    width: 100%;
    text-align: left;
    border-collapse: collapse;
    margin-top: 1rem;
}

    table.exam-time-table td, table.exam-time-table th {
        border: 1px solid #000000;
        padding: 5px 4px;
    }

    table.exam-time-table tbody td {
        font-size: 13px;
    }

    table.exam-time-table thead {
        background: #CFCFCF;
        background: -moz-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
        background: -webkit-linear-gradient(top, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
        background: linear-gradient(to bottom, #dbdbdb 0%, #d3d3d3 66%, #CFCFCF 100%);
        border-bottom: 3px solid #000000;
    }

        table.exam-time-table thead th {
            font-size: 15px;
            font-weight: bold;
            color: #000000;
            text-align: left;
        }

    table.exam-time-table tfoot {
        font-size: 14px;
        font-weight: bold;
        color: #000000;
        border-top: 3px solid #000000;
    }

        table.exam-time-table tfoot td {
            font-size: 14px;
        }

.time-cell {
    display: block;
    padding-left: 0.8em;
    width: 100%;
}

.exam-cell {
    display: block;
    padding-left: 0.8em;
    width: 100%;
}
span.spec25{
    color: red;
}

span.spec50{
    color: darkred;
    background-color: yellow;
}

@media print {
    .pagebreak {
        clear: both;
        page-break-after: always;
    }
    @page {size: landscape}
}
</style>
<template>
    <b-container fluid>
        <h4 v-if="student"> Timetable for {{ student.studentName }}</h4>
        <table class="exam-time-table">
            <thead><tr v-html="getHeader()"></tr></thead>
            <tbody>
                <template>
                    <tr v-for="examDay in examblock.examDays" :key="examDay.examDate" v-html="getRow(examDay)">
                    </tr>
                </template>
            </tbody>
        </table>
        <div class="pagebreak"> </div>
    </b-container>  
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import moment from 'moment'

export default ({
    name: 'ReportPopup',
    props: {
        studentId: String,
        examBlockId: Number,
        examBlock: Object,
        examSubjects: []
    },
    
    data() {
        return {
            examblock: {
                examblockId: 1,
                description: 'Year 10 exams',
                examYear: 7,
                examDays: [{
                    examDayId: 1,
                    examDate: 'Monday 07/03/2022',
                    sessions: [{
                        sessionName: 'SESSION A',
                        startTime: '09:00',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        },
                        {
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        }]
                    },
                    {
                        sessionName: 'SESSION B',
                        startTime: '11:30',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        }]
                    },
                    {
                        sessionName: 'SESSION C',
                        startTime: '14:00',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        }]
                    }]
                },
                {
                    examDayId: 1,
                    examDate: 'Tuesday 08/03/2022',
                    sessions: [{
                        sessionName: 'SESSION A',
                        startTime: '09:00',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        }]
                    },
                    {
                        sessionName: 'SESSION B',
                        startTime: '11:30',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Physics 1',
                            examLength: 60,
                            studentCount: 145,
                            startTime: '14:00'
                        }]
                    },
                    {
                        sessionName: 'SESSION C',
                        startTime: '14:00',
                        clashes: 1,
                        exams: [{
                            examId: 1,
                            examName: 'Maths 1',
                            examLength: 75,
                            studentCount: 145,
                            startTime: '14:30'
                        }]
                    }]
                }
            ],
            unassignedExams:{
                exams: [
                {
                    examId: 4,
                    examName: 'History 1',
                    examLength: 60,
                    studentCount: 145,
                    startTime: '14:00'
                }
            ]}
            },
            subjects: [],
            // studentId: null,
            student: null
        };
    },
    methods: {
        getRow(examDay) {
            let res = `<td>${moment(examDay.examDate).format('dddd, DD MMMM')}</td>`;
            examDay.sessions.forEach(session => {
                let sessionTimes = '<td>';
                let sessionExams = '';
                session.exams.forEach(exam => {
                    if (!this.studentId || this.getExamStudents(exam).filter(s=>s.studentId === this.studentId).length>0) {
                        const startTime = moment(exam.startTime, 'HH:mm').format('HH:mm');
                        const endTime = moment(exam.startTime, 'HH:mm').add(exam.examLength, 'm').format('HH:mm');
                        
                        sessionTimes += '<span class="time-cell">' + startTime + ' - ' + endTime + '</span>';
                        if (exam.timeNotes) {sessionTimes += `<span class="time-cell">${exam.timeNotes}</span>`} else {sessionTimes += '<br/>'}
                        sessionExams += `<span class="exam-cell">${exam.examName} ${exam.examLength}min`;
                        // let extendedLength = Math.ceil(exam.totalLength); // Math.ceil(exam.examLength * 0.25);
                        if (this.studentId) {
                            if (!this.student) {
                                this.student = this.getExamStudents(exam).find(s=>s.studentId === this.studentId);
                            }
                            let extendedLength = Math.ceil(exam.examLength * this.student.extraTime/100);
                            // if (this.student.restBreaks) {
                            //     extendedLength += Math.ceil(exam.examLength * 0.16);
                            // }
                            if (extendedLength) {sessionExams += `<span class="spec25"> + ${Math.ceil(extendedLength)}min</span>`;}
                            sessionExams += ` </span><span class="exam-cell" style="font-weight:bold">${this.writeRooms(exam.rooms)}</span>`;
                        } else {
                            sessionExams += this.writeExamTime(exam); 
                            sessionExams += ` (${exam.studentCount})</span><span class="exam-cell" style="font-weight:bold">${this.writeRooms(exam.rooms)}</span>`;
                        }
                        
                        if (exam.notes) {sessionExams += `<span class="exam-cell">${exam.notes}</span>`} else {sessionExams += '<br/>'}
                    }
                })
                res += sessionTimes  + '</td><td>' + sessionExams + '</td>';
            });
            
            return res;
        },
        getHeader() {
            // <thead><tr v-html="getHeader()"><th>DATE</th><th colspan="2">SESSION A</th><th colspan="2">SESSION B</th><th colspan="2">SESSION C</th></tr></thead>
            let res = '<th>DATE</th>';
            this.examblock.examDays[0].sessions.forEach(session => {
                res += `<th colspan="2">${session.sessionName}</th>`
            });
            return res;
        },
        calculateStudentCounts(examDays) {
            examDays.forEach(day => {
                day.sessions.forEach(session => {
                    session.exams.forEach(exam => {
                        if (exam.parentExamId) {
                            exam.studentCount = exam.studentIds.length;
                            exam.students = exam.studentIds.map(i=> i.id);
                            const parent = this.findExam(examDays, exam.parentExamId);
                            console.log('Parent', parent)
                            if (parent) {
                                parent.studentCount -= exam.studentCount;
                                if (parent.students == null) { parent.students = [];}
                                if (parent.studentIds == null) { parent.studentIds = [];}
                                parent.students.push(...exam.students);
                                parent.studentIds.push(...exam.studentIds);
                            }
                        }
                        // rooms
                        //exam.rooms = exam.roomIds.map(i=> i.roomId);
                    });
                });
            });
            examDays.forEach(day => {
                day.sessions.forEach(session => {
                    session.exams.forEach(exam => {
                        // calulate extratime
                        exam.totalLength = 0;
                        let studentList =this.getExamStudents(exam).filter(s=>s.extraTime>0 || s.restBreaks);
                        if (studentList.length > 0) {
                            let studentList25 = studentList.filter(s=> s.extraTime === 25 );
                            let studentList50 = studentList.filter(s=> s.extraTime === 50 );
                            //let studentListRests = studentList.filter(s=> s.restBreaks );
                            if (studentList25.length>0) {
                                exam.totalLength = exam.examLength * 0.25;
                                exam.extra25 = true; 
                            } 
                            if (studentList50.length>0) {
                                exam.totalLength = exam.examLength * 0.5;
                                exam.extra50 = true;
                                // if (studentList50.filter(s=> s.restBreaks ).length > 0) {
                                //     exam.totalLength = exam.totalLength * 1.16;
                                // } 
                            }  
                        }
                    });
                });
            });
        },
        writeExamTime(exam) {
            // `<span class="spec">+${Math.ceil(extendedLength)}min</span>`;
            let res = '';
            if (exam.extra25) {
                res = `<span class="spec25">+${Math.ceil(exam.examLength * 0.25)}min</span>`
            }
            if (exam.extra50) {
                res += ` <span class="spec50">+${Math.ceil(exam.examLength * 0.5)}min</span>`
            }
            return res;
        },
        getExamStudents(exam) {
            const subject = this.getSubjectFromId(exam.subjectId);
            let studentList = [];
            if (exam.parentExamId) { // if exam is a clone 
                studentList = subject.students.filter(s=> exam.students.includes(s.studentId));
            }
            else if (exam.students && exam.students.length) {
                studentList = subject.students.filter(s=> !exam.students.includes(s.studentId));
            }
            else {
                studentList = subject.students;
            }
            return studentList;
        },
        getSubject(subjectName) {
            let subject = this.subjects.find(o => o.subjectName === subjectName);
            return subject;
        },
        getSubjectFromId(subjectId) {
            let subject = this.subjects.find(o => o.subjectId === subjectId);
            return subject;
        },
        findExam(examDays, examId) {
            let parent = null;
            examDays.forEach(day => {
                if (!parent) {
                    day.sessions.forEach(session => {
                        const exam = session.exams.find(e=>e.examId === examId);
                        
                        if (exam != null) {
                            parent = exam;
                        }
                    });
                    
                    const exam = this.examblock.unassignedExams.exams.find(e=>e.examId === examId);
                    
                    if (exam != null) {
                       parent = exam;
                    }
                }
            });
            
            return parent;
        },
        writeRooms(rooms) {
            
          if (rooms) {
            //console.log('writerooms', Array.from(new Set(this.rooms.filter((item)=> rooms.includes(item.roomId)).map((item) => item.roomName))).toString().replaceAll(',',', '))
            return Array.from(new Set(rooms.filter((item)=> rooms.includes(item.roomId)).map((item) => item.roomName))).toString().replace(/(,)(?=[^,]+$)/, ' and ').replaceAll(',',', ');
          }
          return '';
        },
        writeTimeTable() {
            console.log('Writing Timetable', this.examBlockId);
            console.log(this.studentId);
            if (this.examBlock) {
                this.subjects = this.examSubjects;
                this.examblock = this.examBlock;                
            } else {
                const examblockid = this.examBlockId;
                axios.get(window.API_URL + "examblocks/" + examblockid).then(response => {
                    console.log('Examblock', response.data);
                    this.examblock.description = response.data.description;
                    this.examblock.examblockId = this.$route.params.examblockid;
                    this.examblock.examYear = response.data.examYear;
                    this.examblock.academicYear = response.data.academicYear;
                    axios.get(`${window.API_URL}subjects/${this.examblock.academicYear.replace('/', '-')}/${this.examblock.examYear}`).then(response =>  {
                        console.log('Examblock', response.data);
                        this.subjects = response.data
                        axios.get(window.API_URL + "examblocks/" + examblockid + "/examdays").then(response => {
                            console.log('Examblock', response.data);
                            this.examblock.examDays = response.data;
                            this.calculateStudentCounts(this.examblock.examDays);
                            
                            // if (this.student) {
                            //     this.studentId = this.students[0].studentId;
                            // }
                        });
                    });
                
                });
            }
        // axios.get(`${window.API_URL}examrooms`).then(response =>  {
        //         // console.log('Examblock', response.data);
        //         this.rooms = response.data;
        // });
        
        this.currentExam = {...this.blankExam};    
        }
    },
    watch: {
        studentId(newValue) {
            if (newValue) {
                this.writeTimeTable();
            }
        
        }
    },
    created: function () {
        this.writeTimeTable();
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
