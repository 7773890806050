<template>
    <b-container fluid>
        <b-container>

        </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
//import Toolbar from './Toolbar.vue'
import {router} from '../routes.js'

import axios from 'axios';

export default ({
    name: 'Mfa',
    // components:{Toolbar},
    props: {
        showModal: Boolean
    },
    data() {
        return {
        }
    },
    setup() {
        //this.showModal = false;
    },
    created : function() {
        console.log('MFA CREATED')
        const that = this;
        // const urlParams = new URLSearchParams(window.location.search);
        // const state = urlParams.get('state');
        // const code = urlParams.get('code');
        const state = sessionStorage.getItem('mfa_state');
        const code = sessionStorage.getItem('mfa_code');

        axios.get(`${window.API_URL}auth/mfacallback/?state=${state}&code=${code}`).then(response => {
                // console.log('Examblock', response.data);
            if (response) {
                console.log(response);
                // Returns a new fully authenticated JWT
                sessionStorage.setItem('access_token', response.data);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                sessionStorage.removeItem('mfa_state');
                sessionStorage.removeItem('mfa_code');

                router.replace({name: 'examblock', query: null});
                
            }
        }).catch(function(reason) {
            console.log(reason);
            that.signinFailed = true;
        });
    }

})
</script>
