<style scoped>
@media all {
    table.room {
        border:1px solid #C0C0C0;
		border-collapse:collapse;
		padding:5px;
    }
    .room th {
		border:1px solid #C0C0C0;
		padding:5px;
		background:#F0F0F0;
	}
	.room td {
		border:1px solid #C0C0C0;
		padding:15px;
	}

    .selected {
        background-color: aquamarine;
    }

    .side-label {
        writing-mode: vertical-lr;
        vertical-align: middle;
        align-content: center;
    }
}
    @media print {
        .noprint{
            display: none !important;
        }
    }
</style>

<template>
    <b-container fluid>
        <Toolbar></Toolbar>
        
    <b-container>
        <b-row class="justify-content-md-center">
            <b-col cols="3" class="noprint">
        <b-list-group>
        <b-list-group-item v-for="room in rooms" :key="room.roomId" :active="currentRoom.roomId == room.roomId" button @click="getRoom(room.roomId)">
          {{room.roomName}}
        </b-list-group-item>
        
        </b-list-group>
        <b-button @click="addNewRoom">Add Room</b-button>
      </b-col>
            <b-col>
                <template v-if="currentRoom.roomId > 0">
                    <h3>{{this.currentRoom.roomName}}</h3>
                <table><tr><td></td><td class="text-center">{{currentRoom.details.topLabel}}</td><td></td></tr>
                    <tr><td class="text-center"><span class="side-label">{{currentRoom.details.leftLabel}}</span></td><td>
        <table class="room">
            <tr v-for="row in currentRoom.layout" :key="row.index">
                <td v-for="table in row.tables" :key="table.col" :class="table.selected? 'selected text-center': 'text-center'" 
                @mousedown.prevent="mouseDown(table)" @mouseover.prevent="mouseOver(table)" @mouseup="mouseUp()">
                    <template v-if="table.active === 'up'">
                        <b-iconstack font-scale="2">
                          <b-icon stacked icon="tablet-landscape"></b-icon>
                          <b-icon stacked icon="arrow-up-short" scale="0.8" shift-v="2" ></b-icon>
                        </b-iconstack>
                    </template>
                    <template v-else-if="table.active === 'left'">
                        <b-iconstack font-scale="2">
                          <b-icon stacked icon="tablet" flip-v></b-icon>
                          <b-icon stacked icon="arrow-left-short" scale="0.8" shift-h="-2" ></b-icon>
                        </b-iconstack>
                    </template>
                    <template v-else-if="table.active === 'right'">
                        <b-iconstack font-scale="2">
                          <b-icon stacked icon="tablet"></b-icon>
                          <b-icon stacked icon="arrow-right-short" scale="0.8" shift-h="2" ></b-icon>
                        </b-iconstack>
                    </template>
                    <template v-else-if="table.active === 'down'">
                        <b-iconstack font-scale="2">
                          <b-icon stacked icon="tablet-landscape" flip-h></b-icon>
                          <b-icon stacked icon="arrow-down-short" scale="0.8" shift-v="-2" ></b-icon>
                        </b-iconstack>
                    </template>
                    <template v-if="table.door === 'bottom'">
                        <b-icon-arrow-bar-up scale="2" v-if="table.door" shift-v="-20"></b-icon-arrow-bar-up>
                    </template>
                    <template v-else-if="table.door === 'top'">
                        <b-icon-arrow-bar-down scale="2" v-if="table.door" shift-v="20"></b-icon-arrow-bar-down>
                    </template>
                    <template v-if="table.door === 'right'">
                        <b-icon-arrow-bar-left scale="2" v-if="table.door" shift-h="20"></b-icon-arrow-bar-left>
                    </template>
                    <template v-if="table.door === 'left'">
                        <b-icon-arrow-bar-right scale="2" v-if="table.door" shift-h="-20"></b-icon-arrow-bar-right>
                    </template>
                </td>
            </tr>
        </table>
    </td><td class="text-center"><span class="side-label">{{currentRoom.details.rightLabel}}</span></td></tr>
    <tr><td></td><td class="text-center">{{currentRoom.details.bottomLabel}}</td><td></td></tr></table> 
    <div class="noprint">
    <b-button-group>
        <b-dropdown @click="addRemoveTables('up')" text="Add Tables" split>
            <b-dropdown-item @click="addRemoveTables('up')">Facing up</b-dropdown-item>
            <b-dropdown-divider></b-dropdown-divider>
            <b-dropdown-item @click="addRemoveTables('left')">Facing left</b-dropdown-item>
            <b-dropdown-item @click="addRemoveTables('right')">Facing right</b-dropdown-item>
            <b-dropdown-item @click="addRemoveTables('down')">Facing down</b-dropdown-item>
        </b-dropdown>
    </b-button-group>
        &nbsp;<b-button @click="addRemoveTables('none')">Remove Tables</b-button>
        &nbsp;
        <b-button-group>
            <b-dropdown text="Add Door">
                <b-dropdown-item @click="addDoor('top')">Top</b-dropdown-item>
                <b-dropdown-item @click="addDoor('left')">Left</b-dropdown-item>
                <b-dropdown-item @click="addDoor('right')">Right</b-dropdown-item>
                <b-dropdown-item @click="addDoor('bottom')">Bottom</b-dropdown-item>
                <b-dropdown-divider></b-dropdown-divider>
                <b-dropdown-item @click="addDoor('none')">Remove Door</b-dropdown-item>
            </b-dropdown>

        </b-button-group>

        &nbsp;<b-button @click="editRoom">Edit Details</b-button>
        &nbsp;<b-button @click="saveRoom" variant="primary">Save</b-button>
        </div>
    </template>
    </b-col>
    </b-row>

    <b-modal id="add-exam-room" title="Add Exam Room" @ok="saveExamRoom" v-model="showAdd">
            <b-form-group id="fieldset-name" label="Name" label-for="exam-room-name" >
                <b-form-input id="exam-room-name" v-model="newRoom.roomName" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-rows" label="Rows" label-for="rows">
                <b-form-input id="rows" v-model="newRoom.rows" type="number"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-cols" label="Columns" label-for="cols">
                <b-form-input id="cols" v-model="newRoom.cols" type="number"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-top-label" label="Top Description" title="Description that will appear above the room diagram" label-for="top-label" >
                <b-form-input id="top-label" v-model="newRoom.details.topLabel" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-left-label" label="Left Description" title="Description that will appear above the room diagram" label-for="top-label" >
                <b-form-input id="left-label" v-model="newRoom.details.leftLabel" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-right-label" label="Right Description" title="Description that will appear above the room diagram" label-for="top-label" >
                <b-form-input id="right-label" v-model="newRoom.details.rightLabel" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-bottom-label" label="Bottom Description" title="Description that will appear above the room diagram" label-for="top-label" >
                <b-form-input id="bottom-label" v-model="newRoom.details.bottomLabel" trim></b-form-input>
            </b-form-group>
        </b-modal>
    </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import Toolbar from './Toolbar.vue'

export default ({
    name: 'Rooms',
    props: {
        
    },
    components:{Toolbar},
    data() {
        return {
            showAdd: false,
            editing: false,
            mouseIsDown: false,
            startTable: null,
            topLabel: 'Stage',
            leftLabel: 'Windows',
            rightLabel: 'Walkway',
            bottomLabel: 'Canteen',
            defaultTable: 'up',
            rowCount: 3,
            columnCount: 4,
            blankRoom: {roomId: 0, roomName: '', rows: 10, cols: 10, details: {topLabel: '', leftLabel: '', rightLabel: '', bottomLabel: ''}},
            newRoom: {},
            currentRoom: {roomId: 0, roomName: '', rows: 10, cols: 10, details: {topLabel: '', leftLabel: '', rightLabel: '', bottomLabel: ''}},
            rooms: [{roomId: 1, roomName: 'Hall', rows: [], cols: []}, {roomId: 2, roomName: 'Gym'}],
            rows: [{index: 1, tables: [{row: 1, col: 1, student: '', selected: false, active: 'up', door: true},{row: 1, col: 2, student: '', selected: false, active: 'up'},{row: 1, col: 3, student: '', selected: false, active: 'up'},{row: 1, col: 4, student: '', selected: false, active: 'up'}]},
             {index: 2, tables: [{row: 2, col: 1, student: '', selected: false, active: 'left'},{row: 2, col: 2, student: '', selected: false, active: 'up'},{row: 2, col: 3, student: '', selected: false},{row: 2, col: 4, student: '', selected: false, active: 'right'}]},
             {index: 3, tables: [{row: 3, col: 1, student: '', selected: false, active: 'left'},{row: 3, col: 2, student: '', selected: false, active: 'down'},{row: 3, col: 3, student: '', selected: false},{row: 3, col: 4, student: '', selected: false, active: 'right'}]}
            ]
        }
    },
    methods: {
        mouseDown(table) {
            this.deselectAll();
            this.mouseIsDown = true;
            table.selected = true;
            this.startTable = table;
        },
        mouseOver(table) {
            if(this.mouseIsDown) {
                this.getSelectedTables(table);
                // table.selected = true;
            }
        },
        mouseUp() {
            this.mouseIsDown = false;
        },
        deselectAll() {
            this.currentRoom.layout.forEach(row => {
                row.tables.forEach(table => {
                    table.selected = false;
                });
            });
        },
        getSelectedTables(table) {
            let x1 = Math.min(this.startTable.row, table.row);
            let y1 = Math.min(this.startTable.col, table.col);
            let x2 = Math.max(this.startTable.row, table.row);
            let y2 = Math.max(this.startTable.col, table.col);

            this.deselectAll();
            for(let i=x1; i<= x2; i++) {
                for(let j=y1; j<= y2; j++) {
                    this.currentRoom.layout[i-1].tables[j-1].selected = true;
                }
            }
        },
        addRemoveTables(add) {

            for(let j=0; j< this.currentRoom.layout[0].tables.length; j++) {
                for(let i=0; i< this.currentRoom.layout.length; i++) {
                    let table = this.currentRoom.layout[i].tables[j];
                    if (table.selected) {
                        table.active = add;
                    }
                }
            }
            this.deselectAll();
        },
        getRoom(roomId) {
            axios.get(`${window.API_URL}examrooms/${roomId}`).then(response =>  {
                console.log('Room', response.data);
                let room = {roomId: 0, roomName: '', layout: null};
                room.roomId = response.data.roomId;
                room.roomName = response.data.roomName;
                room.layout = JSON.parse(response.data.layout);
                room.details = JSON.parse(response.data.details);

                this.currentRoom = room;
            });
        },
        addNewRoom() {
            this.newRoom = {...this.blankRoom};
            this.showAdd = true;
            this.editing = false;
        },
        editRoom() {
            this.newRoom.roomName = this.currentRoom.roomName;
            this.newRoom.details = {...this.currentRoom.details};
            this.newRoom.rows = this.currentRoom.layout.length;
            this.newRoom.cols = this.currentRoom.layout[0].tables.length;
            this.showAdd = true;
            this.editing = true;
        },
        saveExamRoom() {
            if (this.editing) {
                this.editing = false;
                this.editColumns(this.newRoom.cols, this.currentRoom.layout);
                this.editRows(this.newRoom.rows, this.newRoom.cols, this.currentRoom.layout);
                this.currentRoom.roomName = this.newRoom.roomName;
                this.currentRoom.details = {...this.newRoom.details};
            } else {
                this.newRoom.layout = [{index: 1, tables: [{row: 1, col: 1, selected: false, active: 'up'}]}];
                this.editColumns(this.newRoom.cols, this.newRoom.layout);
                this.editRows(this.newRoom.rows, this.newRoom.cols, this.newRoom.layout);
                console.log('new room', this.newRoom);
                let room = {roomName: this.newRoom.roomName,
                            layout: JSON.stringify(this.newRoom.layout),
                            details: JSON.stringify(this.newRoom.details)
                        };

                axios.post(`${window.API_URL}examrooms`, room).then(response =>  {
                    console.log('Room', response.data);
                    let room = {roomId: 0, roomName: '', layout: null};
                    room.roomId = response.data.roomId;
                    room.roomName = response.data.roomName;
                    room.layout = JSON.parse(response.data.layout);
                    room.details = JSON.parse(response.data.details);

                    this.currentRoom = room;
                });
            }
        },
        saveRoom() {
            let room = {    roomId: this.currentRoom.roomId,
                            roomName: this.currentRoom.roomName,
                            layout: JSON.stringify(this.currentRoom.layout),
                            details: JSON.stringify(this.currentRoom.details)
                        };
            axios.put(`${window.API_URL}examrooms/${this.currentRoom.roomId}`, room).then(response =>  {
                    console.log('Room', response.data);
                });
        },
        editRows(rowCount, columnCount, layout) {
            console.log(rowCount)
            if (!rowCount) {
                return;
            }
            if (rowCount > layout.length) {
                // add rows
                let rowsAdded = rowCount - layout.length;
                
                for (let i = 0; i < rowsAdded; i++) {
                    let tables = new Array(columnCount);
                    for (let j = 0; j < columnCount; j++) {
                        tables[j] = {row: layout.length + 1,  col: j + 1, selected: false, active: 'up'};
                    }
                    layout.push({index: layout.length + 1, tables: tables});
                }
            }  else {
                // remove rows
                let rowsDeleted = layout.length - rowCount;
                for (let i = 0; i < rowsDeleted; i++) {
                    layout.pop();
                }
            }
        },
        editColumns(columnCount, layout) {
            if (!columnCount) {
                return;
            }
            let oldColumnCount = layout[0].tables.length;
            if (columnCount > oldColumnCount) {
                // add columns
                let colsAdded = columnCount - oldColumnCount;
                
                layout.forEach((row) => {
                    let colIndex = oldColumnCount;
                    for (let i = 0; i < colsAdded; i++) {
                        colIndex++;
                        row.tables.push({row: row.tables[0].row, col: colIndex, selected: false, active: 'up'});
                    }
                });

            }  else {
                // remove columns
                let colsDeleted = oldColumnCount - columnCount;
                layout.forEach((row) => {
                    for (let i = 0; i < colsDeleted; i++) {
                        row.tables.pop();
                    }
                });
                
            }
        },
        addDoor(side) {
            for(let j=0; j< this.currentRoom.layout[0].tables.length; j++) {
                for(let i=0; i< this.currentRoom.layout.length; i++) {
                    let table = this.currentRoom.layout[i].tables[j];
                    if (table.selected) {
                        table.door = side;
                    }
                }
            }
        
            this.deselectAll();
        }
    },
    watch: {
        // rowCount: function() {
        //     console.log(this.rowCount)
        //     if (!this.rowCount) {
        //         return;
        //     }
        //     if (this.rowCount > this.rows.length) {
        //         // add rows
        //         let rowsAdded = this.rowCount - this.rows.length;
                
        //         for (let i = 0; i < rowsAdded; i++) {
        //             let tables = new Array(this.columnCount);
        //             for (let j = 0; j < this.columnCount; j++) {
        //                 tables[j] = {row: this.rows.length + 1,  col: j + 1, selected: false, active: true};
        //             }
        //             this.rows.push({index: this.rows.length + 1, tables: tables});
        //         }
        //     }  else {
        //         // remove rows
        //         let rowsDeleted = this.rows.length - this.rowCount;
        //         for (let i = 0; i < rowsDeleted; i++) {
        //             this.rows.pop();
        //         }
        //     }

        // },
        // columnCount: function() {
        //     if (!this.columnCount) {
        //         return;
        //     }
        //     let oldColumnCount = this.rows[0].tables.length;
        //     if (this.columnCount > oldColumnCount) {
        //         // add columns
        //         let colsAdded = this.columnCount - oldColumnCount;
                
        //         this.rows.forEach((row) => {
        //             let colIndex = oldColumnCount;
        //             for (let i = 0; i < colsAdded; i++) {
        //                 colIndex++;
        //                 row.tables.push({col: colIndex, selected: false, active: true});
        //             }
        //         });

        //     }  else {
        //         // remove columns
        //         let colsDeleted = oldColumnCount - this.columnCount;
        //         this.rows.forEach((row) => {
        //             for (let i = 0; i < colsDeleted; i++) {
        //                 row.tables.pop();
        //             }
        //         });
                
        //     }
        // }
    },
    created() {
        this.currentRoom = {...this.blankRoom};
        this.newRoom = {...this.blankRoom};
        axios.get(window.API_URL + "examrooms").then(response =>  {
            console.log('Room', response.data);
            
            this.rooms = response.data;
            
        });

        
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
