<template>
    <b-container fluid>
        <Toolbar></Toolbar>
        <b-container>
            <b-form-group id="fieldset-password" label="Current Password" label-for="password" >
                <b-form-input id="password" v-model="password" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-new-password" label="New Password" label-for="new-password" :invalid-feedback="invalidPassword" :state="passwordState" >
                <b-form-input id="new-password" v-model="newPassword" type="password"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-password2" label="Repeat" label-for="new-password2" :invalid-feedback="invalidPassword2" :state="password2State">
                <b-form-input id="new-password2" v-model="newPassword2" type="password"></b-form-input>
            </b-form-group>
            <b-alert :show="alertText != ''" variant="danger">{{ this.alertText }}</b-alert>
            <b-alert :show="messageText != ''" variant="success">{{ this.messageText }}</b-alert>
            <b-button @click="changePassword" variant="primary">Change Password</b-button>
        </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import Toolbar from './Toolbar.vue'
import Person from '../modules/Person'

export default ({
    name: 'UserProfile',
    components:{Toolbar},
    props: {
        
    },
    data() {
        return {
            alertText: '',
            messageText: '',
            newPassword: '',
            newPassword2: '',
            person: Person
        }
    },
    computed: {
        passwordState() {
            return this.newPassword.length > 7;
        },
        invalidPassword() {
            return 'Passwords must be 8 or more characters'
        },
        password2State() {
            return this.newPassword === this.newPassword2;
        },
        invalidPassword2() {
            return 'Passwords must match'
        }
    },
    methods: {
        changePassword() {
            console.log('Changing password')
            if (!this.passwordState || !this.password2State) {
                this.alertText = "Passwords must match and be 8 or more characters";
                return;
            }

            axios.post(window.API_URL + "auth/changepassword", {'username': this.person.username, 'password': this.password, 'newPassword': this.newPassword} ).then(response => {
                if(!response || !response.data) {
                    this.alertText = "Password not saved. Make sure current password is correct and new passwords match.";
                }
            });
        }
    },
    setup() {
        //this.showModal = false;
    },
    created () {
        this.person = new Person();
    }
})
</script>
