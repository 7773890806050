<template>
    <b-container fluid>
        <Toolbar></Toolbar>
    <b-container>
        <b-row class="justify-content-md-center">
        <b-col lg="6">
        <h3>Exam Blocks</h3>
        <div class="accordion" role="tablist">
            <b-card  v-for="year in academicYears" :key="year" no-body>
              <b-card-header header-tag="header" class="p-1" role="tab">
                <b-button block v-b-toggle="'accordion-' + year" variant="info">Academic Year {{year}}</b-button>
              </b-card-header>
              <b-collapse :id="'accordion-' + year" accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <hr>
                  <b-list-group v-for="examblock in examblocks.filter((item) => {return item.academicYear === year})" :key="examblock.examblockId">
                    <b-list-group-item>{{examblock.description}} <b-button size="sm" variant="outline-primary" class="float-right" @click="editExamBlock(examblock)"><b-icon-pencil-fill></b-icon-pencil-fill></b-button></b-list-group-item>
                  </b-list-group>
                </b-card-body>
              </b-collapse>
            </b-card>
        </div>
        <b-button class="float-right" size="sm" variant="primary" @click="addExamBlock">
                    Add
                  </b-button>
        </b-col>
        </b-row>
        <b-modal id="add-exam-block" title="Add Exam Block" @ok="saveExamBlock" v-model="showAdd">
            <b-form-group id="fieldset-name" label="Name" label-for="exam-block-name" 
                :invalid-feedback="invalidName" :state="nameState">
                <b-form-input id="exam-block-name" v-model="newExamBlock.description" :state="nameState" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-start-date" label="Start Date" label-for="start-date" 
                :invalid-feedback="invalidName" :state="startDateState">
                <b-form-datepicker id="start-date" v-model="newExamBlock.startDate" locale="en-UK" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" :state="startDateState1"></b-form-datepicker>
            </b-form-group>
            <b-form-group id="fieldset-end-date" label="End Date" label-for="end-date" 
                :invalid-feedback="invalidName" :state="endDateState">
                <b-form-datepicker id="end-date" v-model="newExamBlock.endDate" locale="en-UK" :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit', weekday: 'short' }" :state="endDateState"></b-form-datepicker>
            </b-form-group>
            <b-form-group id="fieldset-exam-year" label="Exam Year" label-for="exam-year" 
                :invalid-feedback="invalidName" :state="examYearState">
                <b-form-select id="exam-year" v-model="newExamBlock.examYear" :state="examYearState" :options="examYears"></b-form-select>
            </b-form-group>
            <b-form-group id="fieldset-academic-tear" label="Academic Year" label-for="academic-year" 
                :invalid-feedback="invalidName" :state="academicYearState">
                <b-form-input id="academic-year" v-model="newExamBlock.academicYear" :state="academicYearState" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-session-count" label="Number of Sessions per Day" label-for="session-count" 
                :invalid-feedback="invalidName" :state="sessionCountState">
                <b-form-input id="session-count" type="number" v-model="newExamBlock.sessionCount" :state="sessionCountState" trim></b-form-input>
            </b-form-group>
            <b-form-group v-for="session in newExamBlock.sessions" :key="session.sessionId" id="fieldset-time" label="Session Time" label-for="session-time" 
                :invalid-feedback="invalidName">
                <b-form-input id="session-time" type="time" v-model="session.startTime"></b-form-input>
            </b-form-group>
        </b-modal>
        </b-container>
    </b-container>
</template>

<script>
// import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import moment from 'moment';
import {router} from '../routes.js'
import Toolbar from './Toolbar.vue'

export default ({
    name: 'ExamBlock',
    components: {Toolbar},
    props: {
        
    },
    data() {
        return {
            showAdd: false,
            newExamBlock: {},
            blankExamBlock: {
                description: '',
                examYear: 7,
                academicYear: '22/23',
                startDate: '',
                endDate: '',
                sessionCount: 3,
                sessions: [
                    {startTime: '09:00', sessionId: 1, sessionName: "SESSION A"}, {startTime: '11:30', sessionId: 2, sessionName: "SESSION B"}, {startTime: '13:30', sessionId: 3, sessionName: "SESSION C"},
                    {startTime: '14:30', sessionId: 4, sessionName: "SESSION D"}
                ]
            },
            examYears: [
                {value: 7, text: 'Year 7'},
                {value: 8, text: 'Year 8'},
                {value: 9, text: 'Year 9'},
                {value: 10, text: 'Year 10'},
                {value: 11, text: 'Year 11'},
                {value: 12, text: 'Year 12'},
                {value: 13, text: 'Year 13'},
            ],
            examblocks: []
        }
    },
    computed: {
        academicYears() {
            return Array.from(new Set(this.examblocks.map((item) => item.academicYear)))
        },
        nameState() {
            return this.newExamBlock.description.length > 3;
        },
        invalidName() {
            return this.newExamBlock.description.length > 3;
        },
        startDateState() {
            return false;
        },
    },
    methods: {
        addExamBlock() {
            this.newExamBlock = {...this.blankExamBlock};
            this.showAdd = true;
        },
        editExamBlock(examblock) {
            //router.push({path: '/examblock/plan/' + examblock.examBlockId});
            router.push({name: 'examblockplan', params: {examblockid: examblock.examBlockId}});
        },
        saveExamBlock() {
            axios.post(window.API_URL + "examblocks", this.newExamBlock).then(response => (
                this.examblocks.push(response.data)
            ));
        }
    },
    created: function () {
        const year = moment(Date.now()).year() - 2000;
        if (moment(Date.now()).month() >= 9) {
            this.academicYear = year.toString() +'/' + (year+1).toString();
        } else {
            this.academicYear = (year-1).toString() +'/' + (year).toString();
        }
        this.blankExamBlock.academicYear = this.academicYear;
        this.newExamBlock = {...this.blankExamBlock};
        axios.get(window.API_URL + "examblocks").then(response => {
            this.examblocks = response.data;
            if (this.examblocks.length === 0) {
                this.addExamBlock()
            }
        });
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
