<style scoped>
    h4.card-title {
        font-size: 1.2rem;
    }
    div.exam h4.card-title {
        font-size: 1.0rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
    }
    div.card.bg-light {
        margin-bottom: 0.8rem;
    }
    div.card.bg-light > .card-body {
        min-width: 320px;
        max-width: 320px;
        padding: 0.8rem;
    }
    div.card.exam {
        margin-left: 0.1rem;
        margin-right: 0.1rem;
    }

    div.exam .card-body {
        padding: 0.8rem;
        min-width: 200px;
    }

    .card-text {
        margin-bottom: 0.2rem;
    }
    .text-muted {
        color: #b7bec4!important;
    }
    .full-height{
        height: calc(100vh - 60px);
        overflow-y: auto;
    }
    .exam-block {
        max-width: 1500px;
        min-width: 1320px;
    }
    .extra-time25 {
        color: red;
    }
    .extra-time50 {
        color: darkred;
    }
    .extra-time-pill25 {
        background-color: red;
        font-size: 0.6rem;
    }
    .extra-time-pill50 {
        background-color: darkred;
        color: yellow;
        font-size: 0.6rem;
    }
    .laptop {
        background-color: purple;
        font-size: 0.6rem;
    }
    .rest-breaks {
        background-color: green;
        font-size: 0.6rem;
    }
    .smaller-room {
        background-color: blue;
        font-size: 0.6rem;
    }
    .notes {
        background-color: orange;
        font-size: 0.6rem;
    }

</style>


<template>
    <b-container fluid>
        <Toolbar></Toolbar>
        
    <b-container fluid class="exam-block" >
        <b-row>
            <b-col cols="9" class="full-height">
                <h2>{{examblock.description}} <b-button @click="showEditDetails()" size="sm">Edit</b-button> <b-button @click="showAddDays = true" size="sm">Add Days</b-button> <b-button @click="showMove = true" size="sm">Move</b-button> <b-button size="sm" :to="'/report/' + examblock.examblockId">Print</b-button> <b-button size="sm" :href="getExportUrl()">Export</b-button> <b-button size="sm" :to="'/workload/' + examblock.examblockId">Workload</b-button> <b-button size="sm">Delete</b-button></h2>
                <div v-for="examDay in examblock.examDays" :key="examDay.examDate">
                    <p>{{examDay.examDate | formatDayDate}} <b-button v-if="!examDay.sessions[0].deleted" size="sm" @click="deleteDay(examDay)" variant="danger">Delete</b-button><b-button v-if="examDay.sessions[0].deleted" variant="success" size="sm" @click="restoreDay(examDay)">Restore</b-button></p>
                    <b-row>
                        <b-card-group deck>
                        <!--<b-col v-for="session in examDay.sessions" :key="session.sessionName" >-->
                            <template v-if="examDay.sessions[0].deleted">
                                <b-card v-for="session in examDay.sessions" :key="session.sessionName" class="text-muted bg-light">
                                    <b-card-title>{{session.sessionName}}</b-card-title>
                                </b-card>
                            </template>
                            <template v-else>
                            <b-card v-for="session in examDay.sessions" :key="session.sessionName"  @drop="dropHandler(session)" @dragenter.prevent @dragover.prevent class="bg-light">
                                <b-card-title>{{session.sessionName}} <small>{{session.sessionTime | formatTime }}</small> <b-badge v-if="session.clashes > 0" variant="danger" :id="examDay.examDate + session.sessionName" title="Clashes">{{session.clashes}} clashes</b-badge></b-card-title>
                                <!-- <b-popover :target="examDay.examDate + session.sessionName" triggers="hover" placement="top">
                                    <template #title>Clashes</template>
                                    <ul>
                                        <li v-for="student in clashPopover" :key="student">{{student}}</li>
                                    </ul>
                                </b-popover> -->
                                <div v-for="exam in session.exams" :key="exam.examId">
                                    <b-card draggable="true" @dragstart="dragStartHandler(exam, session)" @dragenter.prevent @dragover.prevent :class="clashesExamId === exam.examId? 'exam bg-info' : 'exam'">
                                        <b-card-title>{{exam.examName}} <b-icon-pencil @click="openEditExam(exam)" style="cursor:pointer"></b-icon-pencil></b-card-title>
                                        <b-card-text>{{exam.examLength}} <span class="extra-time25" v-if="exam.totalLength != exam.examLength">({{exam.totalLength}}) </span> mins <span @click="openExamStudents(exam)" style="cursor:pointer">({{getStudentCount(exam)}})</span> {{exam.startTime | formatTime}}<b-icon-lightning-fill @click="getClashes(exam)" style="cursor:pointer" :variant="exam.sessionClashes? 'danger' : 'info'"></b-icon-lightning-fill>
                                            <b-badge v-if="exam.extraTime50" class="extra-time-pill50" pill>{{exam.extraTime50}}</b-badge>
                                            <b-badge v-if="exam.extraTime25" class="extra-time-pill25" pill>{{exam.extraTime25}}</b-badge>
                                            <b-badge v-if="exam.laptops" class="laptop" pill>{{exam.laptops}}</b-badge>
                                            <b-badge v-if="exam.restBreaks" class="rest-breaks" pill>{{exam.restBreaks}}</b-badge>
                                            <b-badge v-if="exam.smallerRooms" class="smaller-room" pill>{{exam.smallerRooms}}</b-badge>
                                        </b-card-text>
                                        <b-card-text :id="exam.examId + 'clashes'">
                                            <b-badge v-if="exam.clashes > 0" variant="info" style="width:100%" @click="openClashStudents(exam)">
                                                {{ exam.clashes }}
                                                <b-badge v-if="exam.clashExtraTime50" class="extra-time-pill50" pill>{{exam.clashExtraTime50}}</b-badge>
                                            <b-badge v-if="exam.clashExtraTime25" class="extra-time-pill25" pill>{{exam.clashExtraTime25}}</b-badge>
                                                <b-badge v-if="exam.clashLaptops" class="laptop" pill>{{exam.clashLaptops}}</b-badge>
                                                <b-badge v-if="exam.clashRestBreaks" class="rest-breaks" pill>{{exam.clashRestBreaks}}</b-badge>
                                                <b-badge v-if="exam.clashSmallerRooms" class="smaller-room" pill>{{exam.clashSmallerRooms}}</b-badge>
                                            </b-badge>
                                        </b-card-text>
                                        <!-- <b-popover :target="exam.examId + 'clashes1'" triggers="hover" placement="top">
                                                <template #title>{{clashesSubjectName}} Clashes</template>
                                                <div style="overflow-y: auto; max-height:380px;">
                                                <ul>
                                                    <li v-for="student in exam.studentClashes" :key="student.studentId" :class="'extra-time' + student.extraTime">{{student.studentName}}
                                                        <span v-if="student.extraTime > 0"> (+{{ student.extraTime }}%) </span>
                                                        <b-badge v-if="student.laptop" class="laptop" pill>LT</b-badge>
                                                        <b-badge v-if="student.restBreaks" class="rest-breaks" pill>RB</b-badge>
                                                        <b-badge v-if="student.smallerRoom" class="smaller-room" pill>SR</b-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                        </b-popover> -->
                                    </b-card>
                                </div>
                            </b-card>
                        </template>
                        <!--</b-col>-->
                    </b-card-group>
                    </b-row>
                </div>
            </b-col>
            <b-col class="full-height">
                <b-card title="Unassigned Exams" @drop="dropHandler(examblock.unassignedExams)" @dragenter.prevent @dragover.prevent class="bg-light">
                    <div v-for="exam in examblock.unassignedExams.exams" :key="exam.examId">
                        <!-- <exam-card :exam="exam" draggable="true" @dragstart="dragStartHandler(exam, examblock.unassignedExams)" @dragenter.prevent @dragover.prevent class="exam"
                        @editExam="openEditExam(exam)" @openStudents="openExamStudents(exam)" @clashes="getClashes(exam)" @clashStudents="openClashStudents(exam)"></exam-card> -->
                        <b-card draggable="true" @dragstart="dragStartHandler(exam, examblock.unassignedExams)" @dragenter.prevent @dragover.prevent class="exam">
                            <b-card-title>{{exam.examName}} <b-icon-pencil @click="openEditExam(exam)"></b-icon-pencil></b-card-title>
                            <b-card-text>{{exam.examLength}} mins <span @click="openExamStudents(exam)" style="cursor:pointer">({{exam.studentCount}})</span> {{exam.startTime | formatTime}}<b-icon-lightning @click="getClashes(exam)"></b-icon-lightning></b-card-text>
                            <b-card-text :id="exam.examId + 'clashes'">
                                            <b-badge v-if="exam.clashes > 0" variant="info" style="width:100%" @click="openClashStudents(exam)">{{ exam.clashes }}</b-badge>
                                        </b-card-text>
                                        <!-- <b-popover :target="exam.examId + 'clashes'" triggers="hover" placement="top">
                                                <template #title>{{clashesSubjectName}} Clashes</template>
                                                <div style="overflow-y: auto; max-height:380px;">
                                                <ul>
                                                    <li v-for="student in exam.studentClashes" :key="student.studentId" :class="'extra-time' + student.extraTime">{{student.studentName}}
                                                        <span v-if="student.extraTime > 0"> (+{{ student.extraTime }}%) </span>
                                                        <b-badge v-if="student.laptop" class="laptop" pill>LT</b-badge>
                                                        <b-badge v-if="student.restBreaks" class="rest-breaks" pill>RB</b-badge>
                                                        <b-badge v-if="student.smallerRoom" class="smaller-room" pill>SR</b-badge>
                                                        <b-badge v-if="student.notes" class="notes" pill :id="'studentexam' + student.studentId">N</b-badge>
                                                    </li>
                                                </ul>
                                            </div>
                                        </b-popover> -->
                        </b-card>
                    </div>
                    <b-button @click="openAddExam" size="sm">Add Exam</b-button> <b-button @click="showImport = true" size="sm">Import Exams</b-button>
                </b-card>
            </b-col>
        </b-row>


        <b-modal id="add-exam" :title="currentExam.examId === 0? 'Add Exam' : 'Edit Exam' " @ok="saveExam" @cancel="cancelEditExam" v-model="showAddExam">
            <b-alert :show="duplicateExam" variant="danger">An exam with this name already exists</b-alert>
            <b-form-group id="fieldset-subject" label="Subject" label-for="Subject" >
                <b-form-select id="subject" v-model="currentExam.subjectId" :options="subjects" value-field="subjectId"
                    text-field="subjectName" @change="subjectChange"></b-form-select>
            </b-form-group>

            <b-form-group id="fieldset-name" label="Exam Name" label-for="exam-name" 
                :invalid-feedback="invalidName" :state="nameState">
                <b-form-input id="exam-block-name" v-model="currentExam.examName" :state="nameState" @keyup.enter="saveExam"></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-exam-length" label="Length" label-for="exam-length" >
                <b-form-input id="exam-length" v-model="currentExam.examLength" type="number" @keyup.enter="saveExam"></b-form-input>
            </b-form-group>
            <b-form-group v-if="currentExam.examId > 0" id="fieldset-start-time" label="Start Time" label-for="start-time" >
                <b-form-input id="start-time" v-model="currentExam.startTime" type="time" @keyup.enter="saveExam"></b-form-input>
            </b-form-group>
            <!-- <b-form-group v-if="currentExam.examId > 0" id="fieldset-rooms" label="Rooms" >
                <span>{{ writeRooms(currentExam.rooms) }}</span> &nbsp;
                <b-button @click="editRooms()">Edit</b-button>
            </b-form-group>
            <b-form-group v-if="currentExam.examId > 0" id="fieldset-exam-notes" label="Notes" label-for="exam-notes" >
                <div v-if="notesEditor">
                    <TextEditor :editor="notesEditor"></TextEditor>
                    <editor-content :editor="notesEditor" />
                </div>
            </b-form-group>
            <b-form-group v-if="currentExam.examId > 0" id="fieldset-time-notes" label="Time Notes" label-for="time-notes" >
                <TextEditor :editor="timeNotesEditor"></TextEditor>
                <editor-content :editor="timeNotesEditor" />
            </b-form-group> -->
            <v-multiselect-listbox v-if="currentExam.parentExamId" :options="students" :reduce-display-property="(option) => option.studentName"
                       :reduce-value-property="(option) => option.studentId" v-model="currentExam.students"></v-multiselect-listbox>

            <template #modal-footer="{ cancel }" >
                <div class="w-100">
                <b-button size="sm" variant="danger" mr-auto @click="cloneExam" title="Clone for Clash Resolution" > 
                    Clone
                </b-button>
                <!-- Emulate built in modal footer ok and cancel button actions -->
                <div class="float-right justify-content-betwee">
                <b-button size="sm" variant="secondary" @click="cancel()">
                    Close
                </b-button> &nbsp;
                <b-button size="sm" variant="primary" @click="saveExam" > 
                    OK
                </b-button>
                </div>
                </div>
            </template>
        </b-modal>

        <b-modal id="edit-rooms" title="Exam Rooms" @ok="saveRooms" v-model="showEditRooms">
            <b-form-group id="fieldset-exam-rooms" label="Rooms"  >
                <v-multiselect-listbox :options="rooms" :reduce-display-property="(option) => option.roomName"
                       :reduce-value-property="(option) => option.roomId" v-model="currentExam.rooms"></v-multiselect-listbox>
            </b-form-group>
        </b-modal>

        <b-modal id="add-days" title="Add Days" @ok="saveNewDays" v-model="showAddDays">
            <b-form-group id="fieldset-start-date" label="Start Date" label-for="new-start-date" >
                <b-form-datepicker id="new-start-date" v-model="addDays.startDate"></b-form-datepicker>
            </b-form-group>
            <b-form-group id="fieldset-end-date" label="End Date" label-for="new-end-date" >
                <b-form-datepicker id="new-end-date" v-model="addDays.endDate"></b-form-datepicker>
            </b-form-group>
            
        </b-modal>
        <b-modal id="move-exam-block" title="Move Exam Block" @ok="saveMove" v-model="showMove">
            <b-form-group id="fieldset-start-date" label="From" label-for="move-start-date" >
                <b-form-datepicker id="move-start-date" v-model="addDays.startDate"></b-form-datepicker>
            </b-form-group>
            <b-form-group id="fieldset-end-date" label="To" label-for="move-end-date" >
                <b-form-datepicker id="move-end-date" v-model="addDays.endDate"></b-form-datepicker>
            </b-form-group>
            
        </b-modal>

        <b-modal v-model="showEditBlock" title="Edit Exam Block Details" @ok="saveExamBlock">
            <b-form-group id="fieldset-name" label="Name" label-for="exam-block-name" 
                >
                <b-form-input id="exam-block-name" v-model="examblock.description" trim></b-form-input>
            </b-form-group>
            <b-form-group id="fieldset-top-notes" label="Top Notes" label-for="exam-block-top-notes" 
                >
                <TextEditor :editor="topNotesEditor"></TextEditor>
                <editor-content :editor="topNotesEditor" />
            </b-form-group>
            <b-form-group id="fieldset-bottom-notes" label="Bottom Notes" label-for="exam-block-bottom-notes" 
                >
                <TextEditor :editor="bottomNotesEditor"></TextEditor>
                <editor-content :editor="bottomNotesEditor" />
            </b-form-group>
        </b-modal>

        <b-modal v-model="showImport" title="Import Exams" @ok="importExams">
            <b-card>
                <p>Copy and paste from Excel.<br/> Format: Subject Name, Exam Name, Length</p>
            </b-card>
            <b-form-group id="fieldset-import" label="Exams" label-for="exams">
            <b-form-textarea id="exams" v-model="rawExams" trim></b-form-textarea>
            </b-form-group>
        </b-modal>
        <b-modal v-model="showExamStudents" :title="currentSubject.subjectName + ' Students'">
            <div style="overflow-y: auto; max-height:280px;">
                <ul>
                    <li v-for="student in students" :key="student.studentId" :class="'extra-time' + student.extraTime">{{student.studentName}}
                        <span v-if="student.extraTime > 0"> (+{{ student.extraTime }}%) </span>
                        <b-badge v-if="student.laptop" class="laptop" pill>LT</b-badge>
                        <b-badge v-if="student.restBreaks" class="rest-breaks" pill>RB</b-badge>
                        <b-badge v-if="student.smallerRoom" class="smaller-room" pill>SR</b-badge>
                        <b-badge v-if="student.notes" class="notes" pill :id="'student' + student.studentId">N</b-badge>
                        <b-popover v-if="student.notes" :target="'student' + student.studentId" triggers="hover" placement="top">
                                                <template #title>{{student.studentName}} Notes</template>
                                                <div style="overflow-y: auto; max-height:380px;">
                                                <div v-html="formatNotes(student)"></div>
                                            </div>
                                        </b-popover>
                    </li>
                </ul>
            </div>
            <template #modal-footer="{ ok}">
                <b-button size="sm" variant="primary" @click="ok()">
                    OK
                </b-button>
            </template>
        </b-modal>
        
        <DraggableDiv class="col-3" v-for="studentList in studentLists" :key="studentList.listName" @close="closeStudentList(studentList)"
            @selected="bringToFront(studentList)" :studentList="studentList">
            <template slot="header">
                {{studentList.listName}}
            </template>
            <template slot="main" >
                <div style="overflow-y: auto; max-height:280px;">
                <ul>
                    <li v-for="student in studentList.students" :key="student.studentId" :class="'extra-time' + student.extraTime">
                        <b-badge v-if="student.notes" class="notes" pill :id="studentList.listName + student.studentId">N</b-badge>
                        <b-badge v-else pill :id="studentList.listName + student.studentId">N</b-badge>
                        {{student.studentName}}
                        <span v-if="student.extraTime > 0"> (+{{ student.extraTime }}%) </span>
                        <b-badge v-if="student.laptop" class="laptop" pill>LT</b-badge>
                        <b-badge v-if="student.restBreaks" class="rest-breaks" pill>RB</b-badge>
                        <b-badge v-if="student.smallerRoom" class="smaller-room" pill>SR</b-badge>
                        <b-popover :target="studentList.listName + student.studentId" triggers="hover" placement="top">
                                                <template #title>{{student.studentName}} Notes</template>
                                                <div style="overflow-y: auto; max-height:380px;">
                                                <div v-html="formatNotes(student)"></div>
                                            </div>
                                        </b-popover>
                    </li>
                </ul>
            </div>
            </template>
            <template slot="footer">
            </template>
        </DraggableDiv>
    </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import DraggableDiv from './DraggableDiv'
import TextEditor from './TextEditor.vue'
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
//import ExamCard from './ExamCard.vue';
//import vMultiselectListbox from 'vue-multiselect-listbox'
import Toolbar from './Toolbar.vue'

export default ({
    name: 'ExamBlockPlan',
    components: {
        DraggableDiv, Toolbar, EditorContent, TextEditor//, ExamCard
    },
    props: {
        
    },
    data() {
        return {
            duplicateExam: false,
            showEditBlock: false,
            notesEditor: null,
            timeNotesEditor: null,
            showMove: false,
            showImport: false,
            showAddDays: false,
            showAddExam: false,
            showExamStudents: false,
            showEditRooms: false,
            isDragging: false,
            dragSession: null,
            dragExam: {},
            currentExam: null,
            rawExams: '',
            clashesExamId: 0,
            advancedClashes: false,
            clashesSubjectName: '',
            clashPopover: ['Jim Bob', 'Fruitbat']
            ,
            addDays: {startDate: null, endDate: null},
            blankExam: {
                examId: 0,
                examName: '',
                examLength: 60,
                startTime: '09:00',
                subjectId: 0,
                examBlockId: 0
            },
            examblock: {
                examblockId: 1,
                description: 'Exams',
                examYear: 7,
                examDays: [
            ],
            unassignedExams:{
                exams: [
                {
                    examId: 4,
                    examName: 'History 1',
                    examLength: 60,
                    studentCount: 145,
                    startTime: '14:00'
                }
            ]}
            },
            subjects: [],
            clashes: [],
            students: [],
            currentSubject: {},
            studentsListTitle: 'Students',
            studentLists: [],
            currentStudentList: null,
            rooms: [] //{roomId: 1, roomName: 'Hall'},{roomId: 2, roomName: 'Gym'},{roomId: 3, roomName: 'B5'},{roomId: 4, roomName: 'E4'}]
        };
    },
    methods: {
        
        onMove() {
            // console.log('Moved');
        },
        dropHandler(session) {
            // update time
            this.dragExam.startDate = session.examDate;
            this.dragExam.startTime = session.sessionTime;
            this.dragExam.sessionId = session.sessionId;
            session.exams.push(this.dragExam);
            var index =  this.dragSession.exams.findIndex(x => x.examId==this.dragExam.examId);
            if (index > -1) {
                this.dragSession.exams.splice(index, 1);
            }
            axios.post(window.API_URL + "examsession", this.dragExam) //.then(response => {
                    // console.log('Examblock', response.data);
                //});
            this.showClashes(session);
            this.showClashes(this.dragSession);
        },
        dragStartHandler(exam, session) {
            // console.log('Started')
            this.dragExam = exam;
            this.dragSession = session;
        },
        dragOverHandler() {

        },
        getExportUrl() {
            return window.API_URL + "examblocks/" + this.examblock.examblockId + "/export";
        },
        showEditDetails() {
            
            this.topNotesEditor.commands.setContent(this.examblock.topNotes);
            this.bottomNotesEditor.commands.setContent(this.examblock.bottomNotes);
            this.showEditBlock = true;
        },
        saveExamBlock() {
            
            if (this.topNotesEditor.getText().length > 0) { 
              this.examblock.topNotes = this.topNotesEditor.getHTML();
            } else {
              this.examblock.topNotes = '';
            }
            if (this.bottomNotesEditor.getText().length > 0) { 
              this.examblock.bottomNotes = this.bottomNotesEditor.getHTML();
            } else {
              this.examblock.bottomNotes = '';
            }

            axios.put(window.API_URL + "examblocks/" + this.examblock.examblockId, this.examblock).then(response => {
                // console.log('Examblock', response.data);
                if (response) {
                    this.showEditBlock = false;        
                }
            });
            
        },  
        openAddExam() {
            this.currentExam = {...this.blankExam};
            this.currentExam.examBlockId = this.examblock.examblockId;
            this.notesEditor.commands.clearContent();
            this.timeNotesEditor.commands.clearContent();
            this.duplicateExam = false;
            this.showAddExam = true;
        },
        openEditExam(exam) {
            this.currentExam = {...exam};
            this.dragExam = exam;
            
            this.currentSubject = this.getSubjectFromId(exam.subjectId);
            
            this.students = this.currentSubject.students;

            this.notesEditor.commands.setContent(this.currentExam.notes);
            this.timeNotesEditor.commands.setContent(this.currentExam.timeNotes);
            this.duplicateExam = false;
            this.showAddExam = true;
        },
        saveExam() {
            const isNew = !this.currentExam.examId;

            if(this.findExamWithName(this.examblock.examDays, this.currentExam)) {
                this.duplicateExam = true;
                return;
            }

            if (this.currentExam.startTime == null) {this.currentExam.startTime = '09:00'}
            this.currentExam.startTime = this.currentExam.startTime.substring(0, 5) + ':00';
            if (this.notesEditor.getText().length > 0) { 
              this.currentExam.notes = this.notesEditor.getHTML();
            } else {
              this.currentExam.notes = '';
            }
            
            if (this.timeNotesEditor.getText().length > 0) { 
              this.currentExam.timeNotes = this.timeNotesEditor.getHTML();
            } else {
              this.currentExam.timeNotes = '';
            }
            const oldStudentCount = this.currentExam.studentCount;
            this.currentExam.studentCount = this.getStudentCount(this.currentExam); // this.subjects.find((element => element.subjectId === this.currentExam.subjectId)).studentCount;
            if (!isNew) {
                this.currentExam.studentCount = this.getStudentCount(this.currentExam); // this.subjects.find((element => element.subjectId === this.currentExam.subjectId)).studentCount;
                Object.assign(this.dragExam, this.currentExam);
            }
            else if (!this.currentExam.parentExamId) {
                this.currentExam.studentCount = this.subjects.find((element => element.subjectId === this.currentExam.subjectId)).studentCount;
            }
            axios.post(window.API_URL + "exams", this.currentExam).then(response => {
                // console.log('Examblock', response.data);
                if (isNew && response.data.examId > 0) {
                    this.examblock.unassignedExams.exams.push(response.data);
                }
            });
            if (this.currentExam.parentExamId){
                const parent = this.findExam(this.examblock.examDays, this.currentExam.parentExamId);
                                
                if (parent) {
                    parent.studentCount -= (this.currentExam.studentCount - oldStudentCount);
                    if (parent.students == null) { parent.students = [];}
                    parent.students.push(...this.currentExam.students);
                }
            }
            this.showAddExam = false;
        },
        editRooms() {
            console.log('Rooms', this.currentExam.rooms);
            this.showEditRooms = true;
        },
        saveRooms() {
            console.log('Rooms', this.currentExam.rooms);
        },
        closeStudentList(studentList) {
            this.studentLists = this.studentLists.filter(l => l.listName !== studentList.listName);
        },
        bringToFront(studentList) {
            const currentZ = studentList.zorder;
            let max = 0;
            this.studentLists.forEach(list => {
                if (list.zorder > max) {max = list.zorder;}
                if (list.zorder > currentZ) {
                    list.zorder -= 1;
                }
            });
            studentList.zorder = max;
            this.currentStudentList =studentList;
        },
        getStudentCount(exam) {
            if(exam.parentExamId) {
                return exam.students.length;
            }
            return exam.studentCount;
        },
        openStudents(listName, students) {
            // Already open
            if (this.studentLists.find(l => l.listName === listName)) {
                //TODO: bring to front
                this.bringToFront(this.studentLists.find(l => l.listName === listName));
                return;
            }
            // Show in Unpinned window
            const unpinned = this.studentLists.findIndex(l => !l.pinned);
            if (unpinned >= 0) {
                this.studentLists[unpinned].listName = listName;
                this.studentLists[unpinned].students = students;
                this.studentLists[unpinned].pinned = false;
                this.bringToFront(this.studentLists[unpinned]);

                return;
            }

            //Open list in new window
            const zorder = this.studentLists.length + 100;
            // Find last position
            let pos = localStorage.getItem('studentListPopup');
            if (!pos) {
                pos = {top: "100", left: "100"}
            } else {
                pos = JSON.parse(pos);
            }
            //Is existing list in same postion
            const tmp = this.studentLists.findIndex(l => l.position.top > pos.top - 5 && l.position.top < pos.top + 5 && l.position.left > pos.left - 5 && l.position.left < pos.left + 5);
            if (tmp >= 0) {
            //if (this.currentStudentList) {
            //    pos = this.currentStudentList.position;
                pos.top += 30;
                pos.left += 30;
                localStorage.setItem('studentListPopup', JSON.stringify(pos));
            }

            //if (this.studentLists.length < 2) {
            const newList = {
                    listName: listName,
                    students: students,
                    position: pos,
                    zorder: zorder,
                    pinned: false
                };
            this.studentLists.push(newList);
            this.bringToFront(newList);
            // } else {
            //     this.studentLists[1].listName = listName;
            //     this.studentLists[1].students = students;
            //     this.studentLists[1].pinned = false;
            // }
        },
        openExamStudents(exam) {
            this.currentSubject = this.getSubjectFromId(exam.subjectId);
            let studentsListTitle = this.currentSubject.subjectName + ' Students';
            if (exam.parentExamId) {
                studentsListTitle = exam.examName + ' Students';
                this.students = this.currentSubject.students.filter(s=> exam.students.includes(s.studentId));
            }
            else if (exam.students && exam.students.length) {
                studentsListTitle = exam.examName + ' Students';
                this.students = this.currentSubject.students.filter(s=> !exam.students.includes(s.studentId));
            }
            else {
                this.students = this.currentSubject.students;
            }
            this.openStudents(studentsListTitle, this.students);
        },
        openClashStudents(exam) {
            this.currentSubject = this.getSubjectFromId(exam.subjectId);
            const studentsListTitle = this.clashesSubjectName + '/' + this.currentSubject.subjectName + ' Clashes';
            this.students = exam.studentClashes;
            this.openStudents(studentsListTitle, this.students);
        },
        subjectChange() {
            // console.log('subject change', this.currentExam)
            // if (!this.currentExam.examName) {
                this.currentExam.examName =  this.subjects.find((element => element.subjectId === this.currentExam.subjectId)).subjectName;
            // }
        },
        cloneExam() {
            
            if (this.currentExam.startTime == null) {this.currentExam.startTime = '09:00'}
            this.currentExam.startTime = this.currentExam.startTime.substring(0, 5) + ':00';
            this.currentExam.studentCount = 0; //this.subjects.find((element => element.subjectId === this.currentExam.subjectId)).studentCount;
            this.currentExam.students = [];
            this.currentExam.parentExamId = this.currentExam.parentExamId || this.currentExam.examId;
            this.currentExam.examId = 0;
            this.currentExam.examBlockId = this.examblock.examblockId;
            if (!this.currentExam.examName.toLowerCase().includes('clash')) {
                this.currentExam.examName += ' Clash Resolution';
            }
        },
        cancelEditExam() {
            // console.log('cancelled')
            
        },
        saveNewDays() {
            if (this.examblock.examDays.filter((day) => {return (day.examDate == this.addDays.startDate || day.examDate == this.addDays.endDate) }).length) {
                
                return;
            }
            
            axios.post(window.API_URL + "examblocks/" + this.examblock.examblockId + "/adddays", this.addDays).then(response => {
                // console.log('Examblock', response.data);
                if (response) {
                    this.getDays();
                }
            });
        },
        saveMove() {
            axios.post(window.API_URL + "examblocks/" + this.examblock.examblockId + "/move", this.addDays).then(response => {
                // console.log('Examblock', response.data);
                if (response) {
                    axios.get(window.API_URL + "examblocks/" + this.$route.params.examblockid + "/examdays").then(response => {
                        // console.log('Examblock', response.data);
                        this.examblock.examDays = response.data
                    });
                }
            });
        },
        deleteDay(examDay) {
            const days = this.examblock.examDays.filter((day) => {return day.examDate > examDay.examDate});
            let endpoint = 'deactivateday';
            if (days.length === 0) {
                // last day remove it
                this.examblock.examDays.pop();
                endpoint = 'deleteday';
            }
            axios.post(`${window.API_URL}examblocks/${this.examblock.examblockId}/${endpoint}`, examDay).then(response => {
                // console.log('Examblock', response.data); 
                examDay.sessions = response.data.sessions;
                this.getUnassignedExams();
            });
        },
        restoreDay(examDay) {
            axios.post(window.API_URL + "examblocks/" + this.examblock.examblockId + "/restoreDay", examDay).then(response => {
                // console.log('Examblock', response.data); 
                examDay.sessions = response.data.sessions; 
            });
        },
        getUnassignedExams(){
            axios.get(window.API_URL + "examblocks/" + this.$route.params.examblockid + "/unassigned").then(response => {
                // console.log('Examblock', response.data);
                this.examblock.unassignedExams.exams = response.data;
                this.calculateStudentCounts(this.examblock.examDays, true);
            });
        },
        getClashes(exam) {
            this.clashesExamId = exam.examId;
            this.clashesSubjectName = this.getSubjectFromId(exam.subjectId).subjectName;
            if (this.advancedClashes) {
                exam.clashes = 0;
                let studentList1 =this.getExamStudents(exam).map(s=>s.studentId);
                this.examblock.examDays.forEach(day => {
                    day.sessions.forEach(session => {
                        session.exams.forEach(exam2 => {
                            if (exam2.examId != exam.examId) {
                                exam2.clashes = 0;
                                exam2.studentClashes = [];
                                let studentList2 =this.getExamStudents(exam2);

                                exam2.studentClashes = studentList2.filter(s=>studentList1.includes(s.studentId));
                                exam2.clashes = exam2.studentClashes.length;

                                exam2.clashExtraTime25 = exam2.studentClashes.filter(s=> s.extraTime === 25).length;
                                exam2.clashExtraTime50 = exam2.studentClashes.filter(s=> s.extraTime === 50).length;
                                exam2.clashLaptops = exam2.studentClashes.filter(s=> s.laptop).length;
                                exam2.clashRestBreaks = exam2.studentClashes.filter(s=> s.restBreaks).length;
                                exam2.clashSmallerRooms = exam2.studentClashes.filter(s=> s.smallerRoom).length;
                            }
                        });
                    });
                });
                this.examblock.unassignedExams.exams.forEach(exam2 => {
                    exam2.clashes = 0;
                    if (exam2.examId != exam.examId) {
                                exam2.clashes = 0;
                                exam2.studentClashes = [];
                                let studentList2 =this.getExamStudents(exam2);

                                exam2.studentClashes = studentList2.filter(s=>studentList1.includes(s.studentId));
                                exam2.clashes = exam2.studentClashes.length;
                                exam2.clashExtraTime25 = exam2.studentClashes.filter(s=> s.extraTime === 25).length;
                                exam2.clashExtraTime50 = exam2.studentClashes.filter(s=> s.extraTime === 50).length;
                                exam2.clashLaptops = exam2.studentClashes.filter(s=> s.laptop).length;
                                exam2.clashRestBreaks = exam2.studentClashes.filter(s=> s.restBreaks).length;
                                exam2.clashSmallerRooms = exam2.studentClashes.filter(s=> s.smallerRoom).length;
                            }
                });
            } else {
                axios.get(window.API_URL + "subjects/" + exam.subjectId + "/clashes").then(response => {
                    // console.log('Clashes', response.data);
                    // [{"subject1Id":1008,"clashCount":1}]
                    const clashes = response.data.clashes;
                    const students = response.data.students;
                    
                    this.examblock.examDays.forEach(day => {
                        day.sessions.forEach(session => {
                            session.exams.forEach(exam => {
                                exam.clashes = 0;
                                clashes.forEach(clash => {
                                    if (exam.subjectId == clash.subject1Id) {
                                        exam.clashes = clash.clashCount;
                                    }
                                });
                                exam.studentClashes = [];
                                students.forEach(student => {
                                    if (exam.subjectId == student.subjectId) {
                                        exam.studentClashes.push(student);
                                    }
                                });
                                
                            });
                        });
                    });
                    this.examblock.unassignedExams.exams.forEach(exam => {
                        exam.clashes = 0;
                        clashes.forEach(clash => {
                            if (exam.subjectId == clash.subject1Id) {
                                exam.clashes = clash.clashCount;
                            }
                        });
                        exam.studentClashes = [];
                                students.forEach(student => {
                                    if (exam.subjectId == student.subjectId) {
                                        exam.studentClashes.push(student);
                                    }
                                });
                    });
                });
            }
        },
        getExamStudents(exam) {
            const subject = this.getSubjectFromId(exam.subjectId);
            let studentList = [];
            if (exam.parentExamId) {
                studentList = subject.students.filter(s=> exam.students.includes(s.studentId));
            }
            else if (exam.students && exam.students.length) {
                studentList = subject.students.filter(s=> !exam.students.includes(s.studentId));
            }
            else {
                studentList = subject.students;
            }
            return studentList;
        },
        showClashes(session) {
            session.clashes = 0;
            if (session.exams.length > 0) {
                if (this.advancedClashes) {
                    session.exams.forEach(exam1 => {
                        exam1.sessionClashes = false;
                        let studentList1 =this.getExamStudents(exam1).map(s=>s.studentId);
                        // console.log('List 1', studentList1)
                        session.exams.forEach(exam2 => {
                            if (exam1 != exam2) {
                                let studentList2 =this.getExamStudents(exam2);
                                // console.log('List 2', studentList2)
                                const clashes = studentList2.filter(s=>studentList1.includes(s.studentId));
                                if (clashes.length > 0) {
                                    exam1.sessionClashes = true;
                                    session.clashes = Math.max(session.clashes, clashes.length);
                                }
                            }
                        });
                    });
                }
                else {
                    session.exams.forEach(exam => {
                        exam.sessionClashes = false;
                    });
                    this.examblock.clashes.forEach(clash => {
                        // console.log('clash', clash)
                        session.exams.forEach(exam => {
                            if (exam.subjectId == clash.subject1Id) {
                                session.exams.forEach(exam2 => {
                                    if (exam2.subjectId == clash.subject2Id) {
                                        exam.sessionClashes = true;
                                        exam2.sessionClashes = true;
                                        if (clash.clashCount > session.clashes) {
                                            session.clashes = clash.clashCount;
                                        }
                                    }
                                });
                            }
                        });
                    });
                }
                
            }
        },
        importExams() { 
            let rows = this.rawExams.split("\n");
            let exams = new Array();
                            
            for (var y in rows) {
                var cells = rows[y].split("\t");
                this.mapRow(cells, exams);
            }

            // console.log(exams);
            axios.post(window.API_URL + "exams/import", exams).then(response => {
                // console.log(response);
                if (response) {
                    this.getUnassignedExams();
                }
            });
        },
        mapRow(cells, exams) {
            //Format: Subject Name, Exam Name, Length
            if (cells && cells[0]) {
                let subject = this.getSubject(cells[0]);
                
                if (subject) {
                    exams.push({
                        examName: cells[1],
                        startTime: '09:00:00',
                        examLength: cells[2],
                        subjectId: subject.subjectId,
                        examBlockId: this.examblock.examblockId
                    });
                }
            }
        },
        getSubject(subjectName) {
            let subject = this.subjects.find(o => o.subjectName === subjectName);
            return subject;
        },
        getSubjectFromId(subjectId) {
            let subject = this.subjects.find(o => o.subjectId === subjectId);
            return subject;
        },
        findExam(examDays, examId) {
            let parent = null;
            examDays.forEach(day => {
                if (!parent) {
                    day.sessions.forEach(session => {
                        const exam = session.exams.find(e=>e.examId === examId);
                        
                        if (exam != null) {
                            parent = exam;
                        }
                    });
                    
                    const exam = this.examblock.unassignedExams.exams.find(e=>e.examId === examId);
                    
                    if (exam != null) {
                       parent = exam;
                    }
                }
            });
            
            return parent;
        },
        findExamWithName(examDays, newExam) {
            let foundExam = null;
            examDays.forEach(day => {
                if (!foundExam) {
                    day.sessions.forEach(session => {
                        const exam = session.exams.find(e=>e.examName === newExam.examName && e.examId != newExam.examId);
                        
                        if (exam != null) {
                            foundExam = exam;
                        }
                    });
                    
                    const exam = this.examblock.unassignedExams.exams.find(e=>e.examName === newExam.examName && e.examId != newExam.examId);
                    
                    if (exam != null) {
                        foundExam = exam;
                    }
                }
            });
            if (!foundExam) {        
                const exam = this.examblock.unassignedExams.exams.find(e=>e.examName === newExam.examName && e.examId != newExam.examId);
                
                if (exam != null) {
                    foundExam = exam;
                }
            }

            return foundExam;
        },
        calculateStudentCounts(examDays, justUnassigned = false) {
            if (!justUnassigned) {
                examDays.forEach(day => {
                    day.sessions.forEach(session => {
                        session.exams.forEach(exam => {
                            if (exam.parentExamId) {
                                exam.studentCount = exam.studentIds.length;
                                exam.students = exam.studentIds.map(i=> i.id);
                                const parent = this.findExam(examDays, exam.parentExamId);
                                
                                if (parent) {
                                    parent.studentCount -= exam.studentCount;
                                    if (parent.students == null) { parent.students = [];}
                                    parent.students.push(...exam.students);
                                }
                            }
                            // rooms
                            // exam.rooms = exam.roomIds.map(i=> i.id);
                        });
                    });
                });
                examDays.forEach(day => {
                    day.sessions.forEach(session => {
                        session.exams.forEach(exam => {
                            // calulate extratime
                            exam.totalLength = exam.examLength;
                            let studentList =this.getExamStudents(exam); //.filter(s=>s.extraTime>0 || s.restBreaks);
                            if (studentList.length > 0) {
                                let studentList25 = studentList.filter(s=> s.extraTime === 25 );
                                let studentList50 = studentList.filter(s=> s.extraTime === 50 );
                                //let studentListRests = studentList.filter(s=> s.restBreaks );

                                if (studentList50.length>0) {
                                    exam.totalLength = Math.ceil(exam.examLength * 1.5);
                                    // if (studentList50.filter(s=> s.restBreaks ).length > 0) {
                                    //     exam.totalLength = Math.ceil(exam.totalLength * 1.16);
                                    // } 
                                } else if (studentList25.length>0) {
                                    exam.totalLength = Math.ceil(exam.examLength * 1.25);
                                    // if (studentList25.filter(s=> s.restBreaks ).length > 0) {
                                    //     exam.totalLength = Math.ceil(exam.totalLength * 1.16);
                                    // }
                                } // else if(studentListRests.length > 0) {
                                //     exam.totalLength = Math.ceil(exam.examLength * 1.16);
                                // } 
                                exam.extraTime50 = studentList50.length;
                                exam.extraTime25 = studentList25.length;
                                exam.laptops = studentList.filter(s=> s.laptop).length;
                                exam.restBreaks = studentList.filter(s=> s.restBreaks).length;
                                exam.smallerRooms = studentList.filter(s=> s.smallerRoom).length;

                            }
                        });
                    });
                });
            }
            this.examblock.unassignedExams.exams.forEach(exam => {
                if (exam.parentExamId) {
                    exam.studentCount = exam.studentIds.length;
                    exam.students = exam.studentIds.map(i=> i.id);
                    const parent = this.findExam(examDays, exam.parentExamId);
                    
                    // console.log('Parent', parent);
                    if (parent) {
                        parent.studentCount -= exam.studentCount;
                        if (parent.students == null) { parent.students = [];}
                            parent.students.push(...exam.students);
                    }
                }
            });
        },
        getDays() {
            axios.get(window.API_URL + "examblocks/" + this.$route.params.examblockid + "/examdays").then(response => {
            let examDays = response.data;
            
            axios.get(window.API_URL + "examblocks/" + this.$route.params.examblockid + "/clashes").then(response => {
                this.examblock.clashes = response.data;
                this.calculateStudentCounts(examDays);
                examDays.forEach(day => {        
                    day.sessions.forEach(session => {
                        this.showClashes(session);
                    });
                });
                
                this.examblock.examDays = examDays;
            });
        });
        },
        formatNotes(student) {
            let res = ''; 
            if (student.notes) {res = student.notes + '<hr>';}
            
            if (this.advancedClashes) {
                if (!student.subjects) {
                    student.subjects = [];
                    this.subjects.forEach(subject => {
                        if (subject.students.find(s=> s.studentId === student.studentId)) {
                            student.subjects.push({subjectId: subject.subjectId, subjectName: subject.subjectName})
                        }
                    });
                }
                let subjects = Array.from(new Set(student.subjects.map((item) => item.subjectName))).toString().replaceAll(',',', ');
                res += subjects;
            }
            
            return res;
        },
        writeRooms(rooms) {
          if (rooms) {
            //console.log('writerooms', Array.from(new Set(this.rooms.filter((item)=> rooms.includes(item.roomId)).map((item) => item.roomName))).toString().replaceAll(',',', '))
            return Array.from(new Set(this.rooms.filter((item)=> rooms.includes(item.roomId)).map((item) => item.roomName))).toString().replaceAll(',',', ');
          }
          return '';
        }
    },
    created: function () {
        axios.get(window.API_URL + "examblocks/" + this.$route.params.examblockid).then(response => {
            // console.log('Examblock', response.data);
            this.examblock.description = response.data.description;
            this.examblock.examblockId = this.$route.params.examblockid;
            this.examblock.examYear = response.data.examYear;
            this.examblock.academicYear = response.data.academicYear;
            axios.get(`${window.API_URL}subjects/${this.examblock.academicYear.replace('/', '-')}/${this.examblock.examYear}`).then(response =>  {
                // console.log('Examblock', response.data);
                this.subjects = response.data;
                if (this.subjects && this.subjects.length) {
                    this.advancedClashes = this.subjects[0].students.length;
                }
                this.getDays();
            });
            // axios.get(`${window.API_URL}examrooms`).then(response =>  {
                // console.log('Examblock', response.data);
                // this.rooms = response.data;
            // });
        });
        
        this.getUnassignedExams();
        this.currentExam = {...this.blankExam};
        const extensions = [StarterKit,
                Document,
                Paragraph,
                Text,
                TextStyle,
                Color,];
        this.notesEditor = new Editor({content: '', extensions: extensions,});
        this.timeNotesEditor = new Editor({content: '', extensions: extensions,});
        this.topNotesEditor = new Editor({content: '', extensions: extensions,});
        this.bottomNotesEditor = new Editor({content: '', extensions: extensions,});
    },
    computed: {
        dragOptions() {
        return {
            animation: 0,
            group: "description",
            disabled: !this.editable,
            ghostClass: "ghost"
        };
        },
        
    },
    watch: {
        isDragging(newValue) {
        if (newValue) {
            this.delayedDragging = true;
            return;
        }
        this.$nextTick(() => {
            this.delayedDragging = false;
        });
        }
    },
    setup() {
        //this.showModal = false;
    },
    beforeDestroy() {
        this.notesEditor.destroy();
        this.timeNotesEditor.destroy();
        this.topNotesEditor.destroy();
        this.bottomNotesEditor.destroy();
    },
})
</script>
