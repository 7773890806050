<style>
  .count {
    font-weight: bold;
    text-align: right;
  }
</style>

<template>
  <b-container fluid>
        <Toolbar></Toolbar>
        
    <b-container>
      <h2>Students</h2>
        <hr>
        <b-row>
      <b-col cols="3">
        <b-form-group id="fieldset-academic-year" label="Academic Year" label-for="academic-year" >
                <b-form-select id="academic-year" v-model="yearGroup.academicYear" :options="academicYears" @change="getStudents(false)"></b-form-select>
            </b-form-group>
        <b-list-group>
        <b-list-group-item :active="yearGroup.year === 7" button @click="getStudents(7)">
          Year 7
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(8)" :active="yearGroup.year === 8">
          Year 8
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(9)" :active="yearGroup.year === 9">
          Year 9
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(10)" :active="yearGroup.year === 10">
          Year 10
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(11)" :active="yearGroup.year === 11">
          Year 11
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(12)" :active="yearGroup.year === 12">
          Year 12
        </b-list-group-item>
        <b-list-group-item button @click="getStudents(13)" :active="yearGroup.year === 13">
          Year 13
        </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
        <!-- <b-form-group id="fieldset-student-filter" label="Filter by" label-for="student-filter" >
                <b-form-select id="student-filter" v-model="yearGroup.academicYear" :options="academicYears" @change="getStudents(false)"></b-form-select>
            </b-form-group> -->
          <b-row>
            <b-col md="6">
            <b-button :pressed.sync="showAll" size="sm" variant="primary">
                <template v-if="showAll">Show Access Arrangements Only</template><template v-else>Show All</template>
            </b-button> <!-- <b-button size="sm" @click="addStudent()" class="mr-2">Add Student</b-button>
            <b-button size="sm" @click="showImportStudents = true" class="mr-2">Import</b-button> -->
          </b-col>
          <b-col offset-md="3" md="3">
            <span class="count"> Student Count: {{ this.yearGroup.students.length }}</span>
          </b-col>
          </b-row>
          <hr>
          <b-table striped outlined small head-variant="light" hover :items="yearGroup.students" :fields="fields">
            <template #cell(edit)="data">
             
              <b-button size="sm" @click="editStudent(data.item)" class="mr-2">
                Edit
              </b-button>
              <!-- <b-button size="sm" @click="printTimeTable(data.item)" class="mr-2">
                Timetable
              </b-button> -->
            </template>
            <template #cell(notes)="data">
                <span v-html="data.value"></span><hr v-if="data.value.length"><span style="font-weight:bold;">Subjects taken: {{ writeSubjects(data.item.subjects) }}</span>
            </template>
            <template #cell(extraTime)="data">
                <span v-if="data.value==50" style="background-color: yellow; color: #a0030e; font-weight: bold;">{{ data.value }}%</span>
                <span v-else-if="data.value>0" style="color: #a0030e; font-weight: bold;">{{ data.value }}%</span>
            </template>
            <template #cell(accessArrangements)="data">
                <span v-if="data.value.indexOf('laptop') >= 0" style="color:#700ca8;">Laptop<br></span>
                <span v-if="data.value.indexOf('restbreaks')>=0" style="color:#2ca70c;">Rest Breaks<br></span>
                <span v-if="data.value.indexOf('smallerroom')>=0" style="color:#3883cf;">Smaller Room<br></span>
            </template>
          </b-table>
          <!-- <div v-if="yearGroup.year > 0">
          <b-button size="sm" @click="addStudent()" class="mr-2">Add Student</b-button>
          <b-button size="sm" @click="showImportStudents = true" class="mr-2">Import Students</b-button>
        </div> -->
      </b-col>
    </b-row>

    <b-modal v-model="showEdit" :title="editTitle" @ok="saveStudent">
      <b-form-group id="fieldset-name" label="Name" label-for="student-name" >
        <b-form-input id="student-name" v-model="currentStudent.studentName" trim></b-form-input>
      </b-form-group>
      <b-form-group id="fieldset-id" label="ID" label-for="student-id">
        <b-form-input id="student-id" v-model="currentStudent.studentId" trim></b-form-input>
      </b-form-group>
      <b-form-group label="Subjects">
         <p>{{writeSubjects(currentStudent.subjects) }}</p>
      </b-form-group>
      <b-form-group label="Special Requirements">
      <b-form-checkbox-group
        v-model="currentStudent.accessArrangements"
        :options="senOptions"
        name="sen">
      </b-form-checkbox-group> 
      </b-form-group>
      <b-form-group id="fieldset-extra-time" label="Extra Time" label-for="extra-time">
        <b-form-input id="extra-time" v-model="currentStudent.extraTime" type="number" trim></b-form-input>
      </b-form-group>
      <!-- <b-form-group id="fieldset-laptop" label="Laptop" label-for="laptop">
        <b-form-checkbox id="laptop" v-model="currentStudent.laptop"></b-form-checkbox>
      </b-form-group>
      <b-form-group id="fieldset-rest-breaks" label="Rest Breaks" label-for="rest-breaks">
        <b-form-checkbox id="rest-breaks" v-model="currentStudent.restBreaks"></b-form-checkbox>
      </b-form-group>
      <b-form-group id="fieldset-smaller-room" label="Smaller Room" label-for="smaller-room">
        <b-form-checkbox id="smaller-room" v-model="currentStudent.smallerRoom"></b-form-checkbox>
      </b-form-group>-->
      <!-- <b-form-group id="fieldset-notes" label="Notes" label-for="student-notes">
        <b-form-input id="student-notes" v-model="currentStudent.notes"></b-form-input>
      </b-form-group> -->
      <div v-if="editor">
        <input
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color"
        >
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        bold
        </button>
        <button @click="editor.chain().focus().setColor('#700ca8').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#700ca8' })}">
        purple
        </button>
        <button @click="editor.chain().focus().setColor('#a0030e').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#a0030e' })}">
        red
        </button>
        <button @click="editor.chain().focus().setColor('#cd7803').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#cd7803' })}">
        orange<!--#f49109-->
        </button>
        <button @click="editor.chain().focus().setColor('#3883cf').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#3883cf' })}">
        blue
        </button>
        <button @click="editor.chain().focus().setColor('#2ca70c').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#2ca70c' })}">
        green
        </button>
        <button @click="editor.chain().focus().unsetColor().run()">
        Black
        </button>
        <editor-content :editor="editor" />
      </div>
    </b-modal>

    <b-modal v-model="showImportStudents" title="Import Student AA Requirements" @ok="importStudents">
      <b-card>
        <p>Copy and paste from Excel.<br/> Format: StudentName, Form, SEN, Need, IEP, Laptop, Extra Time, Rest Breaks, Small Room, Notes   </p>
      </b-card>
      <b-form-group id="fieldset-studentList" label="Student List" label-for="studentList">
        <b-form-textarea id="studentList" v-model="rawStudentList" trim></b-form-textarea>
      </b-form-group>
    </b-modal>

    <b-modal v-model="showSelectExamBlock" title="Timetable" @ok="openTimeTable">
      <b-form-group id="fieldset-examblock" label="Examblock" label-for="examblock">
        <b-form-select id="exam-block" v-model="examBlock" :options="examBlocks" text-field="description" value-field="examBlockId" ></b-form-select>
      </b-form-group>
    </b-modal>

    <b-modal v-model="showTimeTable" title="Timetable" @ok="showTimeTable = false" size="xl">
      <report-popup v-for="student in selectedStudents" :studentId="student.studentId" :examBlockId="examBlock" :key="student.studentId"></report-popup>
    </b-modal>
    </b-container>
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import axios from 'axios';
import { Editor, EditorContent } from '@tiptap/vue-2'
import StarterKit from '@tiptap/starter-kit'
import { Color } from '@tiptap/extension-color'
import Document from '@tiptap/extension-document'
import Paragraph from '@tiptap/extension-paragraph'
import Text from '@tiptap/extension-text'
import TextStyle from '@tiptap/extension-text-style'
import Toolbar from './Toolbar.vue'
import ReportPopup from './ReportPopup.vue'
import moment from 'moment';

export default ({
    name: 'Students',
    components: { EditorContent, Toolbar, ReportPopup },
    props: {
        showModal: Boolean
    },
    data() {
      return {
        showAll: true,
        showSelectExamBlock: false,
        showTimeTable: false,
        selectedStudents: [],
        rawStudentList: null,
        editor: null,
        showEdit: false,
        editTitle: 'Edit Student',
        showImport: false,
        showImportStudents: false,
        academicYear: 0,
        fields: ['studentName',{ key: 'tutorGroup', label: 'Form' }, 'extraTime','accessArrangements', 'notes', { key: 'edit', label: '' }],
        yearGroup: {
          year: 7,
          academicYear: '22-23',
          students: [{studentName: 'KENNISON Tom', studentId: 'A0054', tutorGroup: '13T', extraTime: 25, notes: '<strong><span style="color: #a0030e">For all subjects</span>:  <span style="color: #2ca70c">Rest breaks of ten minutes every hour.</span>  <span style="color: #cd7803">Needs easy access to toilet due to Crohns Disease. Sit at rear of Hall. </span>Subjects taken: Music, Design &amp; Physics.</strong>',
          accessArrangements:['laptop', 'restbreaks']},
           {studentName: 'Griffin Alfie', studentId: 'A0067', tutorGroup: '13H', extraTime: 50, notes: '<strong><span style="color: #a0030e">For all subject.</span>  Subjects taken: Design, Physics & PE</strong>',
           accessArrangements:['laptop', 'smallerroom']}]
        },
        academicYears: [],
        blankStudent: {studentName: '', studentId: '', extraTime: 0, notes: '', accessArrangements:[]
        ,isNew: true},
        currentStudent: {studentName: 'KENNISON Tom', studentId: 'A0054', tutorGroup: '13T', extraTime: 25, notes: '<strong><span style="color: #a0030e">For all subjects</span>:  <span style="color: #2ca70c">Rest breaks of ten minutes every hour.</span>  <span style="color: #cd7803">Needs easy access to toilet due to Crohns Disease. Sit at rear of Hall. </span>Subjects taken: Music, Design &amp; Physics.</strong>', accessArrangements:[]
        },
        student: null,
        senOptions: [{text: 'Laptop', value: 'laptop'},{text: 'Rest Breaks', value: 'restbreaks'},{text: 'Smaller Room', value: 'smallerroom'}] ,
        allStudents: null,
        examBlocks: [],
        examBlock: null
      }
    },
    watch: {
        // whenever question changes, this function will run
        showAll(newShow) {
            if (newShow) {
                this.yearGroup.students = this.allStudents;    
            } else {
                this.yearGroup.students = this.allStudents.filter(s=> (s.hasAccessArrangements));
            }
        }
    },
    methods: {
        addStudent() {
            this.currentStudent = {...this.blankStudent};
            this.editor.commands.clearContent();
            this.showEdit = true;
        },
        editStudent(student) {
            this.student = student;
            this.currentStudent = {...student};
            this.editor.commands.setContent(this.currentStudent.notes);
            this.showEdit = true;
        },
        getStudents: function(year) {
            if (year) {
                this.yearGroup.year = year;
            }
            axios.get(`${window.API_URL}students2/${this.yearGroup.academicYear}/${this.yearGroup.year}`).then(response => {
                this.allStudents = response.data;
                if (this.showAll) {
                    this.yearGroup.students = response.data;
                } else {
                    this.yearGroup.students = this.allStudents.filter(s=> (s.hasAccessArrangements));
                }
            });
            axios.get(`${window.API_URL}examblocks/${this.yearGroup.academicYear}/${this.yearGroup.year}`).then(response => {
                this.examBlocks = response.data;
                // if (this.examBlocks) {
                //   this.examBlock = this.examBlocks[0];
                // }
            });
        },
        saveStudent() {
            console.log(this.editor.getHTML())

            if (this.editor.getText().length > 0) { 
              this.currentStudent.notes = this.editor.getHTML();
            } else {
              this.currentStudent.notes = '';
            }
            this.currentStudent.laptop = this.currentStudent.accessArrangements.includes('laptop');
            this.currentStudent.restBreaks = this.currentStudent.accessArrangements.includes('restbreaks');
            this.currentStudent.smallerRoom = this.currentStudent.accessArrangements.includes('smallerroom');
            this.currentStudent.hasAccessArrangements = (this.currentStudent.extraTime > 0 || this.currentStudent.accessArrangements.length 
              || this.currentStudent.notes);
            Object.assign(this.student, this.currentStudent);
            
            if (this.currentStudent.isNew) {
                axios.post(window.API_URL + "students", this.currentStudent).then(response => {
                    console.log(response);
                    this.currentStudent = response.data;
                    this.yearGroup.students.push({...this.currentStudent});
                });
            } else {
                axios.put(window.API_URL + "students/" + this.currentStudent.studentId, this.currentStudent).then(response => (
                    console.log(response)
                ));
            }
        },
        importStudents() { 
          
          let rows = this.rawStudentList.split("\n");
          let students = new Array();
                      
          for (var y in rows) {
            var cells = rows[y].split("\t");
            this.mapStudentRow(cells, students);
          }

          console.log(students);
          axios.post(window.API_URL + "students/aa", students).then(response => {
            console.log(response);
            this.getStudents();
          });
        },
        mapStudentRow(cells, students) {
        //             0          1    2     3    4     5        6           7           8          9
        // Format: StudentName, Form, SEN, Need, IEP, Laptop, Extra Time, Rest Breaks, Small Room, Notes 
        
        if (cells && cells[0]) {
          
          students.push({
            studentName: this.mapStudentName(cells[0]),
            tutorGroup: cells[1],
            laptop: !!cells[5],
            extraTime:this.mapExtraTime(cells[6]),
            restBreaks: !!cells[7],
            smallRoom: !!cells[8],
            notes: cells[9],
            studentYear: this.yearGroup.year,
            academicYear: this.yearGroup.academicYear,
            
          });
        }
      },
      mapExtraTime(cell) {
        if (cell == "25%") {
          return 25;
        }
        else if (cell == "50%") {
          return 50;
        }
        return 0;
      },
      mapStudentName(name) {
        if (name.includes(',')) return name;
        return name.split(' ').join(', ');

      },
        writeSubjects(subjects) {
          if (subjects) {
            return Array.from(new Set(subjects.map((item) => item.subjectName))).toString().replaceAll(',',', ');
          }
          return '';
        },
        openTimeTable() {
          
          this.showSelectExamBlock = false;
          this.showTimeTable = true;
        },
        printTimeTable(student) {
          
          //const routeData = this.$router.resolve({route: 'studentreport', query: {examBlockId: this.examBlock.examBlockId, studentId: this.currentStudent.studentId}});
          // const routeData = this.$router.resolve({path: 'report/'+ this.examBlock + '/student/' + this.currentStudent.studentId});
          // window.open(routeData.href, '_blank');

          this.selectedStudents.push(student);
          this.showSelectExamBlock = true;
        }
    },
    mounted() {
        this.editor = new Editor({
            content: '',
            extensions: [
                StarterKit,
                Document,
                Paragraph,
                Text,
                TextStyle,
                Color,
            ],
        });

        const year = moment(Date.now()).year() - 2000;
        if (moment(Date.now()).month() >= 9) {
            this.yearGroup.academicYear = year.toString() +'-' + (year+1).toString();
        } else {
            this.yearGroup.academicYear = (year-1).toString() +'-' + (year).toString();
        }

        for(let i=year;i>19;i--) {
          this.academicYears.push({value: i.toString() +'-' + (i+1).toString(), text: i.toString() +'/' + (i+1).toString()});
        }
                
    },
    beforeDestroy() {
        this.editor.destroy()
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
