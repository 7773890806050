import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import VueRouter from 'vue-router'
import './plugins/bootstrap-vue'
import App from './App.vue'
import {router}  from './routes.js'
import vSelect from 'vue-select'
import moment from 'moment'
import vMultiselectListbox from 'vue-multiselect-listbox'
//const vMultiselectListbox = require('vue-multiselect-listbox');
import 'vue-multiselect-listbox/dist/vue-multi-select-listbox.css';

Vue.config.productionTip = false
Vue.use(VueRouter)
Vue.component('v-select', vSelect)
Vue.component('v-multiselect-listbox', vMultiselectListbox)

Vue.filter('formatDayDate', function(value) {
  if (value) {
    return moment(String(value)).format('dddd DD/MM/YYYY')
  }
}
)

Vue.filter('formatDate', function(value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
}
)
Vue.filter('formatTime', function(value) {
  if (value) {
    if (moment.isDate(value) || (String(value).includes('T'))) {
      return moment(String(value)).format('HH:mm')
    } else  {
      return moment(String(value), 'HH:mm:ss').format('HH:mm')
    }
    
  }
}
)

new Vue({
  router: router,
  render: h => h(App),
}).$mount('#app')


