<template>
        <div v-if="editor">
        <input
        type="color"
        @input="editor.chain().focus().setColor($event.target.value).run()"
        :value="editor.getAttributes('textStyle').color"
        >
        <button @click="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
        bold
        </button>
        <button @click="editor.chain().focus().setColor('#700ca8').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#700ca8' })}">
        purple
        </button>
        <button @click="editor.chain().focus().setColor('#a0030e').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#a0030e' })}">
        red
        </button>
        <button @click="editor.chain().focus().setColor('#cd7803').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#cd7803' })}">
        orange<!--#f49109-->
        </button>
        <button @click="editor.chain().focus().setColor('#3883cf').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#3883cf' })}">
        blue
        </button>
        <button @click="editor.chain().focus().setColor('#2ca70c').run()" :class="{ 'is-active': editor.isActive('textStyle', { color: '#2ca70c' })}">
        green
        </button>
        <button @click="editor.chain().focus().unsetColor().run()">
        Black
        </button>
        <!-- <editor-content :editor="editor" /> -->
      </div>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
//import axios from 'axios';
import { Editor } from '@tiptap/vue-2'
// import StarterKit from '@tiptap/starter-kit'
// import { Color } from '@tiptap/extension-color'
// import Document from '@tiptap/extension-document'
// import Paragraph from '@tiptap/extension-paragraph'
// import Text from '@tiptap/extension-text'
// import TextStyle from '@tiptap/extension-text-style'

export default ({
    name: 'TextEditor',
    //components:{EditorContent},
    props: {
        editor: Editor
    },
    watch: {
        // whenever question changes, this function will run
        // content(newContent) {
        //     if (newContent) {
        //         this.editor.setContent(newContent);    
        //     } else {
        //         this.editor.clearContent();
        //     }
        // }
    },
    data() {
        return {
            //editor: null
        }
    },
    setup() {
        
    },
    beforeDestroy() {
        //this.editor.destroy()
    },
    mounted () {
        // this.editor = new Editor({
        //     content: '',
        //     extensions: [
        //         StarterKit,
        //         Document,
        //         Paragraph,
        //         Text,
        //         TextStyle,
        //         Color,
        //     ],
        // });
    }
})

export class TextContent {
    
    getHtml(){
        return this.html;
    }
    


  }
</script>
