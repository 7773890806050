<style>
    .excess {
        color: red;
    }
</style>

<template>
    <b-container fluid>
        <Toolbar></Toolbar>
        
    <b-container fluid>
        <h2><b-button variant="outline-primary" :to="'/examblock/plan/' + examBlockId"><b-icon-arrow-left></b-icon-arrow-left></b-button> Student Workload</h2>
        <b-button @click="filterStudents" size="sm" variant="primary">Show Total Times above: </b-button> <input type="number" v-model="filterValue"/>
        <b-table-simple small hover>
            <b-thead head-variant="light">
                <b-tr><b-th>Student Name</b-th><b-th>Extra Time</b-th><b-th>Rest Breaks</b-th><b-th>Days</b-th><b-th>Total Time</b-th><b-th>Exams</b-th></b-tr>
            </b-thead>
            <b-tbody>
                <b-tr v-for="student in students" :key="student.studentId">
                    <b-td>{{ student.studentName }}</b-td>
                    <b-td>{{ student.extraTime > 0 ? student.extraTime: '' }}</b-td>
                    <b-td><span v-if="student.restBreaks"><b-icon-check variant="success" font-scale="3"></b-icon-check></span></b-td>
                    <b-td colspan="3">
                        <b-table-simple><b-tr v-for="day in student.days" :key="day.examDate">
                            <b-td :class="examClass(day.totalExamLength, student.extraTime, student.restBreaks)">{{ timeFormat(day.examDate) }}</b-td>
                            <b-td :class="examClass(day.totalExamLength, student.extraTime, student.restBreaks)">{{ examLength(day.totalExamLength, student.extraTime, student.restBreaks) }}</b-td>
                            <b-td :class="examClass(day.totalExamLength, student.extraTime, student.restBreaks)">{{ day.exams }}</b-td>
                        </b-tr></b-table-simple>
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <!-- <b-table striped outlined small head-variant="light" hover :items="students" :fields="fields">
            <template #cell(days)="data">
                <div v-for="day in data.value" :key="day.examDate" style="min-width: 150px;">
                    <span>{{ timeFormat(day.examDate) }}</span>
                    <br>
                </div>
            </template>
            <template #cell(time)="data">
                <div v-for="day in data.item.days" :key="day.examDate">
                    <span>{{ examLength(day.totalExamLength, data.item.extraTime, data.item.restBreaks) }}</span>
                    <br>
                </div>
            </template>
            <template #cell(exams)="data">
                <div v-for="day in data.item.days" :key="day.examDate">
                    <span>{{ day.exams }}</span>
                    <br>
                </div>
            </template>
            <template #cell(extraTime)="data">
                <span v-if="data.value > 0">{{ data.value }}</span>
            </template>
            <template #cell(restBreaks)="data">
                <span v-if="data.value"><b-icon-check variant="success" font-scale="3"></b-icon-check></span>
            </template>
        </b-table> -->
    </b-container>  
    </b-container>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'
import moment from 'moment'
import axios from 'axios';
import Toolbar from './Toolbar.vue'

export default ({
    name: 'Workload',
    props: {
        
    },
    components:{Toolbar},
    data() {
      return {
        examBlockId: null,
        filterValue: 180,
        allStudents:[{studentId: "A0001",
        "studentName": "Abdul, Nadim",
        "extraTime": 0,
        "restBreaks": false,
        "days": [
            {
                "examDate": "2023-03-06T00:00:00",
                "totalExamLength": 120,
                "exams": "Psychology: Applied Psychology Paper 1"
            },
            {
                "examDate": "2023-03-07T00:00:00",
                "totalExamLength": 120,
                "exams": "Economics: Markets & Behaviour Paper 1"
            },
            {
                "examDate": "2023-03-08T00:00:00",
                "totalExamLength": 120,
                "exams": "RS: Philosophy"
            },
            {
                "examDate": "2023-03-10T00:00:00",
                "totalExamLength": 120,
                "exams": "Economics: The National & Global Economy Paper 2"
            },
            {
                "examDate": "2023-03-13T00:00:00",
                "totalExamLength": 360,
                "exams": "RS: Ethics, Psychology: Themes Through Core Studies Paper 2, Psychology: Themes Through Core Studies Paper 2 [Clash Resolution 1named 25% ET student]"
            },
            {
                "examDate": "2023-03-14T00:00:00",
                "totalExamLength": 120,
                "exams": "Economics: Microeconomics & Macroeconomics Paper 3"
            },
            {
                "examDate": "2023-03-16T00:00:00",
                "totalExamLength": 240,
                "exams": "Psychology: Research Methods Paper 3, RS: Developments in Christian Thought"
            }
        ]}]
    ,
        students: [],
        fields: ['studentName', 'extraTime', 'restBreaks', {key: 'days', label:'Days'}, {key: 'time', label:'Total Time'},  {key: 'exams', label:'Exams'}]
      }
    },
    mounted() {
        this.examBlockId = this.$route.params.examblockid;

        axios.get(`${window.API_URL}examblocks/${this.$route.params.examblockid}/workload`).then(response => {
            this.students = response.data;
            this.allStudents = response.data;
    });
        
    },
    methods:{
        timeFormat(value){
            return moment(value).format('ddd, DD MMMM')
        },
        examLength(length, extraTime, restBreaks) {
            let res =  length * (100 + extraTime)/100;
            console.log(restBreaks);
            // if (restBreaks) {
            //     res = res * 1.16;
            // }
            
            return Math.ceil(res);
        },
        examClass(length, extraTime, restBreaks) {
            let examLength = this.examLength(length, extraTime, restBreaks);

            if (examLength >= this.filterValue) {
                return 'excess';
            }
            return 'normal';
        },
        filterStudents() {
            const that = this;
            this.students = this.allStudents.filter(s=> s.days.some(d=>that.examLength(d.totalExamLength, s.extraTime, s.restBreaks) >= this.filterValue));
        }
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
