export default class Person {
    
    get username(){
        return this.user.nameid;
    }

    get userRole(){
        return this.user.role;
    }

    get user() {
        if (this.userToken == null) {
            this.userToken = this.parseJwt(sessionStorage.getItem('access_token'));
        }
        return this.userToken;
    }

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join(''));

      return JSON.parse(jsonPayload);
    }
    
  }