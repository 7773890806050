  
<style>
  #draggable-container {
    position: absolute;
    z-index: 9;
  }
  #draggable-header {
    z-index: 10;
    background-color: blue;
    color: white;
    padding: 0.2rem;
  }
  .float-right {
    margin-top: 5px;
    margin-right: 5px;
    cursor: pointer;
  }
</style>

<template>
  <div ref="draggableContainer" id="draggable-container" :style="cssProps" @click="$emit('selected')">
    <div id="draggable-header" @mousedown="dragMouseDown">
      <slot name="header"></slot><b-icon-x-square class="float-right" @click="$emit('close')"></b-icon-x-square>
      <b-icon v-if="studentList.pinned" icon="pin-fill" title="Unpin student list" class="float-right" @click="studentList.pinned = !studentList.pinned"></b-icon>
      <b-icon v-else icon="pin-angle-fill" title="Pin student list" class="float-right" @click="studentList.pinned = !studentList.pinned"></b-icon>
    </div>
    <b-card class="bg-light">
      <slot name="main"></slot>
    </b-card>
    <div>
      <slot name="footer"></slot>
    </div>  
  </div>
</template>
  
<script>
  export default {
    name: 'DraggableDiv',
    props: ['studentList'],
    data: function () {
      return {
        positions: {
          clientX: undefined,
          clientY: undefined,
          movementX: 0,
          movementY: 0
        }
      }
    },
    methods: {
      dragMouseDown: function (event) {
        event.preventDefault()
        // get the mouse cursor position at startup:
        this.positions.clientX = event.clientX
        this.positions.clientY = event.clientY
        document.onmousemove = this.elementDrag
        document.onmouseup = this.closeDragElement
      },
      elementDrag: function (event) {
        event.preventDefault()
        this.positions.movementX = this.positions.clientX - event.clientX;
        this.positions.movementY = this.positions.clientY - event.clientY;
        this.positions.clientX = event.clientX;
        this.positions.clientY = event.clientY;
        // set the element's new position:
        this.studentList.position.top = this.$refs.draggableContainer.offsetTop - this.positions.movementY;
        this.studentList.position.left = this.$refs.draggableContainer.offsetLeft - this.positions.movementX;
        this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px';
        this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px';
        // persist position
        localStorage.setItem('studentListPopup', JSON.stringify({'top': this.studentList.position.top, 'left': this.studentList.position.left}));
      },
      closeDragElement () {
        document.onmouseup = null;
        document.onmousemove = null;
      }
    },
    mounted: function() {
      // let pos = localStorage.getItem('studentListPopup');
      // if (!pos) {
      //   pos = {top: "100", left: "100"}
      // } else {
      //   pos = JSON.parse(pos);
      // }
      //pos.top += 30;
      //pos.left += 30;
      //localStorage.setItem('studentListPopup', JSON.stringify(pos));
      const pos = this.studentList.position;
      this.$refs.draggableContainer.style.top = pos.top + 'px';
      this.$refs.draggableContainer.style.left = pos.left + 'px';

      //this.$refs.draggableContainer.style.width = '400px'
    },
    computed: {
      cssProps() {
        return {
          'position': 'absolute',
          'z-index': this.studentList.zorder
        };
      }
    }
  }
</script>
