<style scoped>
    .row {
        min-height: calc(100vh - 100px);
    }
</style>
<template>
    <b-container>
      <b-row class="justify-content-md-center" align-v="center">
        <b-col lg="4">
            <b-card class="bg-light">
        <b-form-group id="fieldset-name" label="User Name" label-for="username" >
            <b-form-input id="username" v-model="user.username" trim></b-form-input>
        </b-form-group>
        <b-form-group id="fieldset-password" label="Password" label-for="password" >
            <b-form-input id="password" v-model="user.password" type="password" @keyup.enter="onEnter"></b-form-input>
        </b-form-group>
        <div class="text-right">
            <b-button @click="signin()" variant="primary">Sign In</b-button> <b-button @click="signinMfa()" variant="primary">Sign In With 2FA</b-button>
        </div>
        <b-alert :show="signinFailed" variant="danger">Username or password incorrect</b-alert>
        </b-card>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>
  //import { defineComponent } from '@vue/composition-api'
  import axios from 'axios';
  import {router} from '../routes.js'

export default ({
    name: 'SignIn',
    props: {
        
    },
    data() {
        return {
            user: {
                username: "",
                password: ""
            },
            signinFailed: false
        }
    },
    methods: {
        onEnter() {
            this.signin();
        },
        signin() {
            let that = this;

            axios.post(window.API_URL + 'auth/signin', this.user).then(function(response) {
                console.log(response);
                sessionStorage.setItem('access_token', response.data);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                
                 router.replace({name: 'examblock'});
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        signinMfa() {
            // with 2fa
            let that = this;
            axios.post(window.API_URL + 'auth/signinmfa', this.user).then(function(response) {
                console.log(response);
                sessionStorage.setItem('access_token', response.data.token);
                axios.defaults.headers.common['Access_Token'] = sessionStorage.getItem('access_token');
                //const user = this.parseJwt(sessionStorage.getItem('access_token'));
                sessionStorage.setItem('state', response.data.state);
                
                window.location.replace(response.data.promptUri);
                
            }).catch(function(reason) {
                console.log(reason);
                that.signinFailed = true;
            })
        },
        parseJwt (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
        }
    },
    created: function () {
      
        
    },
    setup() {
        //this.showModal = false;
    },
})
</script>
