<template>
      <b-container fluid>
        <Toolbar></Toolbar>
        
  <b-container>
    <h2>Subjects</h2><hr>
    <b-row>
      <b-col cols="3">
        <b-form-group id="fieldset-academic-year" label="Academic Year" label-for="academic-year" >
                <b-form-select id="academic-year" v-model="examSubjects.academicYear" :options="academicYears" @change="getSubjects(false)"></b-form-select>
            </b-form-group>
        <b-list-group>
        <b-list-group-item :active="examSubjects.year === 7" button @click="getSubjects(7)">
          Year 7
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(8)" :active="examSubjects.year === 8">
          Year 8
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(9)" :active="examSubjects.year === 9">
          Year 9
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(10)" :active="examSubjects.year === 10">
          Year 10
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(11)" :active="examSubjects.year === 11">
          Year 11
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(12)" :active="examSubjects.year === 12">
          Year 12
        </b-list-group-item>
        <b-list-group-item button @click="getSubjects(13)" :active="examSubjects.year === 13">
          Year 13
        </b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col>
          <b-table striped outlined small head-variant="light" hover :items="examSubjects.subjects" :fields="fields">
            <template #cell(edit)="data">
              <b-button size="sm" @click="editSubject(data.item)" class="mr-2">
                Edit
              </b-button>
            </template>
          </b-table>
          <div v-if="examSubjects.year > 0">
          <b-button size="sm" @click="addSubject()" class="mr-2">Add Subject</b-button>
          <b-button size="sm" @click="showImport = true" class="mr-2">Import Clashes</b-button>
          <b-button size="sm" @click="showImportStudents = true" class="mr-2" variant="primary">Import Students</b-button>
        </div>
      </b-col>
    </b-row>


    <b-modal v-model="showEdit" :title="editTitle" @ok="saveSubject">
      <b-form-group id="fieldset-name" label="Name" label-for="subject-name" 
        :invalid-feedback="invalidName" :state="nameState">
        <b-form-input id="subject-name" v-model="currentSubject.subjectName" :state="nameState" trim></b-form-input>
      </b-form-group>
      <b-form-group id="fieldset-student-count" label="Student Count" label-for="student-count">
        <b-form-input id="student-count" v-model="currentSubject.studentCount" type="number" trim></b-form-input>
      </b-form-group>
    </b-modal>

    <b-modal v-model="showImport" title="Import Clashes" @ok="importClashes">
      <b-card>
        <p>Copy and paste from Excel.<br/> Format: First Subject, Student Count, Second Subject, Student Count, Clashes</p>
      </b-card>
      <b-form-group id="fieldset-clashes" label="Clashes" label-for="clashes">
        <b-form-textarea id="clashes" v-model="rawClashes" trim></b-form-textarea>
      </b-form-group>
    </b-modal>

    <b-modal v-model="showImportStudents" title="Import Subjects & Students" @ok="importStudents">
      <b-card>
        <p>Copy and paste from Excel.<br/> Format: StudentId, StudentName, TutorGroup, Subjects   </p>
      </b-card>
      <b-form-group id="fieldset-studentList" label="Student List" label-for="studentList">
        <b-form-textarea id="studentList" v-model="rawStudentList" trim></b-form-textarea>
      </b-form-group>
    </b-modal>
  </b-container>
  </b-container> 
</template>

<script>
  import axios from 'axios';
  //import {router} from '../routes.js'
  //import {AuthLevelEnum} from "./../enums"
  import Toolbar from './Toolbar.vue'
  import moment from 'moment';

  export default ({
    name: 'Admin',
    props: {
      showModal: Boolean,
      person: Object
    },
    components:{Toolbar},
    data() {
      return {
        showEdit: false,
        editTitle: 'Edit Subject',
        showImport: false,
        showImportStudents: false,
        rawClashes: '',
        newSubjectId: -1,
        currentSubject: {subjectId: 1, subjectName: 'French', studentCount: 120},
        newSubject: {subjectId: 0, subjectName: '', studentCount: 0, year: 7},
        fields: ['subjectName', 'studentCount', { key: 'edit', label: '' }],
        examSubjects: {
          year: 0,
          academicYear: '22-23',
          subjects: []
        },
        academicYears: []
        
      }
    },
    methods: {
      getSubjects: function(year) {
        if (year) {
          this.examSubjects.year = year;
        }
        axios.get(`${window.API_URL}subjects/${this.examSubjects.academicYear}/${this.examSubjects.year}`).then(response => (
            this.examSubjects.subjects = response.data
        ));
      },
      editSubject(subject) { 
        console.log(subject.name)
        this.currentSubject = subject;
        this.showEdit = true;
      },
      saveSubject() {
        if (this.currentSubject.subjectId > 0) {
          axios.put(window.API_URL + "subjects/" + this.currentSubject.subjectId, this.currentSubject).then(response => (
              console.log(response)
          ));
        } else {
          axios.post(window.API_URL + "subjects", this.currentSubject).then(response => {
            console.log(response);
            this.currentSubject = response.data;
            this.examSubjects.subjects.push({...this.currentSubject});
          });
        }
        
      },
      addSubject() { 
        this.currentSubject = {...this.newSubject};
        this.currentSubject.year = this.examSubjects.year;
        this.currentSubject.academicYear = this.examSubjects.academicYear;
        this.showEdit = true;
      },
      importClashes() { 
        let rows = this.rawClashes.split("\n");
        let clashes = new Array();
        //let subjects = new Array();
        this.newSubjectId = -1;
            
        //Get Subjects from DB
        //May be none so will insert new subjects
            
        for (var y in rows) {
          var cells = rows[y].split("\t");
          this.mapRow(cells, clashes);
        }

        console.log(clashes);
        axios.post(window.API_URL + "subjects/clashes", clashes).then(response => {
            console.log(response);
            this.getSubjects(this.examSubjects.year);
          });
      },
      importStudents() { 
        let rows = this.rawStudentList.split("\n");
        let students = new Array();
                    
        for (var y in rows) {
          var cells = rows[y].split("\t");
          this.mapStudentRow(cells, students);
        }

        console.log(students);
        axios.post(window.API_URL + "subjects/students", students).then(response => {
            console.log(response);
            this.getSubjects(this.examSubjects.year);
          });
      },
      mapRow(cells, clashes) {
        //Format: First Subject, Student Count, Second Subject, Student Count, Clashes        
        if (cells && cells[0]) {
          let subject1 = this.getSubject(cells[0], cells[1]);
          let subject2 = this.getSubject(cells[2], cells[3]);
          let clashCount = cells[4];

          clashes.push({
            subject1: subject1,
            subject2: subject2,
            clashCount: clashCount,
            specialRequirementsCount: 0
          });
        }
      },
      mapStudentRow(cells, students) {
        //Format: First Subject, Student Count, Second Subject, Student Count, Clashes   
        // New format: StudentId, StudentName, TutorGroup, subjects     
        let subjects = [];

        if (cells && cells[0]) {
          for (let i=3; i < cells.length; i++) {
            if (cells[i]) {
              let newSubject = this.getSubject(cells[i], 0);
              if (subjects.findIndex(s=>s.subjectName === newSubject.subjectName) === -1) {
                subjects.push(this.getSubject(cells[i], 0));
              }
            }
          }          
          
          students.push({
            studentId: cells[0],
            studentName: cells[1],
            tutorGroup: cells[2],
            studentYear: this.examSubjects.year,
            academicYear: this.examSubjects.academicYear,
            subjects: subjects
          });
        }
      },
      getSubject(subjectName, studentCount) {
        let subject = this.examSubjects.subjects.find(o => o.subjectName === subjectName);
        if (!subject) {
          subject = {
            subjectId: this.newSubjectId--,
            subjectName: subjectName,
            studentCount: studentCount,
            year: this.examSubjects.year,
            academicYear: this.examSubjects.academicYear
          };
          //Save subject
          this.examSubjects.subjects.push(subject);
        }
        else {
          subject.studentCount = studentCount;
          //let i = subjects.findIndex(o => o.subjectName === subjectName);
          //subjects[i].studentCount = studentCount;
        }

        return subject;
      }
    },
    computed: {
      nameState() {
            return this.currentSubject.subjectName.length > 3;
        },
        invalidName() {
            return '';
        },
    },
    mounted: function() {
      const year = moment(Date.now()).year() - 2000;
        if (moment(Date.now()).month() >= 9) {
            this.examSubjects.academicYear = year.toString() +'-' + (year+1).toString();
        } else {
            this.examSubjects.academicYear = (year-1).toString() +'-' + (year).toString();
        }

        for(let i=year;i>19;i--) {
          this.academicYears.push({value: i.toString() +'-' + (i+1).toString(), text: i.toString() +'/' + (i+1).toString()});
        }
      
  },
})
</script>